<template>
  <div :class="`formulate-input-element formulate-input-element--${context.class}`" :data-type="context.type">
    <b-input-group class="mt-3">
      <b-form-input
        v-model="result.email"
        :placeholder="context.attributes.placeholder"
        :readonly="isSent"
        autocomplete="off"
        type="email"></b-form-input>
      <b-input-group-append>
        <b-button :disabled="isSent || !isValidEmail || !result.email" variant="primary" @click="send()">
          <template v-if="ttl && ttl > 0">
            {{ $t('dashboard.term.remained') }}
            {{ moment(ttl * 1000).format('mm:ss') }}
          </template>
          <template v-else>
            {{ $t('dashboard.term.sendVerificationCode') }}
          </template>
        </b-button>
      </b-input-group-append>
    </b-input-group>
    <ul v-if="errorMessage" class="list-unstyled text-danger">
      <li class="formulate-input-error">
        {{ errorMessage }}
      </li>
    </ul>
    <ul v-if="!isValidEmail" class="list-unstyled text-danger">
      <li class="formulate-input-error">
        {{ $t('dashboard.term.invalidEmail') }}
      </li>
    </ul>
    <b-input-group :prepend="$t('dashboard.term.verificationCode')" class="mt-3">
      <b-form-input v-model="result.code" autocomplete="off"></b-form-input>
    </b-input-group>
    <template v-if="isSent">
      <p class="text-muted font-size-sm mt-2">
        {{ $t('dashboard.auth.register.msg1') }}
        <br />
        {{ $t('dashboard.auth.register.msg2') }}
        <template v-if="ttl > 0">
          {{
            $t('dashboard.auth.register.msg3', {
              time: moment(ttl * 1000).format('mm:ss')
            })
          }}
        </template>
        <a class="send-tag" @click="send()">{{ $t('dashboard.auth.register.msg4') }}</a>
        {{ $t('dashboard.auth.register.msg5') }}
      </p>
    </template>
  </div>
</template>

<script>
import { computed, reactive, ref, watch } from '@vue/composition-api'
import moment from 'moment'
import UserService from '@/app/dashboard/services/user-service'

export default {
  name: 'email-verification-input',
  props: ['context'],
  setup(props) {
    const result = reactive({
      email: '',
      code: ''
    })
    const isSent = ref(false)
    const errorMessage = ref(null)
    const ttl = ref(null)

    const isValidEmail = computed(() => {
      if (!result.email) {
        return true
      }
      const regexp =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return regexp.test(result.email)
    })

    watch(result, (v) => {
      if (v) {
        props.context.model = {
          email: result.email,
          code: result.code
        }
      }
    })

    let timer

    const send = async () => {
      try {
        stopTimer()
        ttl.value = 0
        ttl.value = (await UserService.emailVerifyCreate(result.email)).ttl
      } catch (e) {
        errorMessage.value = e.response.data.email[0]
        setTimeout(() => {
          errorMessage.value = null
        }, 3000)
        return false
      }

      if (ttl.value) {
        timer = setInterval(() => {
          if (ttl.value > 0) {
            ttl.value--
          } else {
            isSent.value = false
            stopTimer()
            ttl.value = null
          }
        }, 1000)
      }
      isSent.value = true
    }

    const stopTimer = () => {
      if (timer) {
        clearInterval(timer)
      }
    }

    const resend = () => {
      isSent.value = true
    }

    return {
      result,
      isSent,
      isValidEmail,
      errorMessage,
      ttl,
      moment,
      send,
      resend
    }
  }
}
</script>

<style lang="scss" scoped>
.send-tag {
  color: #2c7be5;
  background-color: transparent;
  cursor: pointer;
}
</style>
