import { set } from '@vue/composition-api'

const state = () => ({
  annotatorType: '',
  isProgress: false,
  progressMessage: '',
  selectedTools: {},
  currentSourceId: '',
  panels: {},
  toolList: [],
  toolPosition: 'left',
  shortcutList: [],
  header: {
    features: []
  },
  preferences: {
    annotation: {
      fontSize: 13
    },
    display: {
      mode: 'dark' // auto, dark, light
    },
    developer: {
      debug_mode: 'disable'
    }
  }
})

// getters
const getters = {
  isProgress(state) {
    return state.isProgress
  },
  annotationFontSize(state) {
    return state.preferences.annotation.fontSize
  },
  selectedTools(state) {
    if (!state.selectedTools[state.annotatorType]) {
      if (state.toolList.length === 0) {
        set(state.selectedTools, state.annotatorType, [])
      } else {
        return state.toolList.filter((d) => d.default)
      }
    }
    return state.selectedTools[state.annotatorType]
  },
  selectedActTool(state) {
    if (!state.selectedTools[state.annotatorType]) {
      if (state.toolList.length === 0) {
        set(state.selectedTools, state.annotatorType, [])
      } else {
        return state.toolList.find((d) => d.default)
      }
    }
    return state.selectedTools[state.annotatorType].find((d) => d.mode === 'act')
  },
  currentSourceId(state) {
    return state.currentSourceId
  },
  annotatorType(state) {
    return state.annotatorType
  },
  panels: (state) => (projectId, annotatorMode) => {
    if (state.panels[projectId] && state.panels[projectId][annotatorMode]) {
      return state.panels[projectId][annotatorMode]
    } else {
      return {
        horizontalPanels: { list: [] },
        verticalPanels: { list: [] },
        windowPanels: { list: [] }
      }
    }
  },
  verticalPanels: (state) => (projectId, annotatorMode) => {
    if (state.panels[projectId] && state.panels[projectId][annotatorMode]) {
      return state.panels[projectId][annotatorMode].verticalPanels
    } else {
      return {
        list: []
      }
    }
  },
  horizontalPanels: (state) => (projectId, annotatorMode) => {
    if (state.panels[projectId] && state.panels[projectId][annotatorMode]) {
      return state.panels[projectId][annotatorMode].horizontalPanels
    } else {
      return {
        list: []
      }
    }
  },
  windowPanels: (state) => (projectId, annotatorMode) => {
    if (state.panels[projectId] && state.panels[projectId][annotatorMode]) {
      return state.panels[projectId][annotatorMode].windowPanels
    } else {
      return {
        list: []
      }
    }
  },
  shortcutList(state) {
    return state.shortcutList
  },
  header(state) {
    return state.header
  },
  headerFeatures(state, getters) {
    return getters.header.features
  },
  preferences(state) {
    return state.preferences
  },
  display(state, getters) {
    return getters.preferences.display
  },
  developer(state, getters) {
    return getters.preferences.developer
  },
  toolList(state) {
    return state.toolList
  }
}

// mutations
const mutations = {
  setAnnotatorType(state, annotatorType) {
    state.annotatorType = annotatorType
  },
  setDisplay(state, display) {
    state.preferences.display = { ...display }
  },
  setDeveloper(state, developer) {
    state.preferences.developer = { ...developer }
  },
  setProgress(state, { isProgress, message }) {
    state.isProgress = isProgress
    if (!isProgress) {
      state.progressMessage = ''
    } else {
      state.progressMessage = message
    }
  },
  setSelectedActTool(state, tool) {
    if (!state.selectedTools[state.annotatorType]) {
      set(state.selectedTools, state.annotatorType, [])
    }

    state.selectedTools[state.annotatorType] = state.selectedTools[state.annotatorType].filter((d) => d.mode !== 'act')
    state.selectedTools[state.annotatorType].push(tool)
  },
  resetSelectedActTool(state) {
    if (!state.selectedTools[state.annotatorType]) {
      set(state.selectedTools, state.annotatorType, [])
    }

    state.selectedTools[state.annotatorType] = state.selectedTools[state.annotatorType].filter((d) => d.mode !== 'act')
  },
  setSelectedToggleTool(state, tool) {
    if (!state.selectedTools[state.annotatorType]) {
      set(state.selectedTools, state.annotatorType, [])
    }
    const index = state.selectedTools[state.annotatorType].findIndex((d) => d.code === tool.code)
    if (index > -1) {
      state.selectedTools[state.annotatorType] = state.selectedTools[state.annotatorType].filter(
        (d) => d.code !== tool.code
      )
    } else {
      if (state.selectedTools[state.annotatorType].find((d) => d.code === tool.code)) return false

      state.selectedTools[state.annotatorType].push(tool)
    }
  },
  setCurrentSourceId(state, id) {
    state.currentSourceId = id
  },
  setPanels(state, { panels, projectId, annotatorMode }) {
    if (!state.panels[projectId]) {
      set(state.panels, projectId, {})
    }
    if (!state.panels[projectId][annotatorMode]) {
      set(state.panels[projectId], annotatorMode, {})
    }
    state.panels[projectId][annotatorMode] = panels
  },
  setToolList(state, toolList) {
    state.toolList = toolList
  },
  setToolPosition(state, toolPosition) {
    state.toolPosition = toolPosition
  },
  setShortcutList(state, shortcutList) {
    state.shortcutList = shortcutList
  },
  setHeader(state, header) {
    state.header = header
  },
  setVerticalPanels(state, { projectId, verticalPanels, annotatorMode }) {
    if (!state.panels[projectId]) {
      set(state.panels, projectId, {})
    }
    if (!state.panels[projectId][annotatorMode]) {
      set(state.panels[projectId], annotatorMode, {})
    }
    state.panels[projectId][annotatorMode].verticalPanels = verticalPanels
  },
  setHorizontalPanels(state, { projectId, horizontalPanels, annotatorMode }) {
    if (!state.panels[projectId]) {
      set(state.panels, projectId, {})
    }
    if (!state.panels[projectId][annotatorMode]) {
      set(state.panels[projectId], annotatorMode, {})
    }
    state.panels[projectId][annotatorMode].horizontalPanels = horizontalPanels
  },
  setWindowPanels(state, { projectId, windowPanels, annotatorMode }) {
    if (!state.panels[projectId]) {
      set(state.panels, projectId, {})
    }
    if (!state.panels[projectId][annotatorMode]) {
      set(state.panels[projectId], annotatorMode, {})
    }
    state.panels[projectId][annotatorMode].windowPanels = windowPanels
  },
  setPreferences(state, preferences) {
    state.preferences = { ...preferences }
  },
  setAnnotationFontSize(state, fontSize) {
    state.preferences.annotation.fontSize = fontSize
  }
}

// actions
const actions = {
  setAnnotationFontSize({ commit }, fontSize) {
    commit('setAnnotationFontSize', fontSize)
  },
  setAnnotatorType({ commit }, annotatorType) {
    commit('setAnnotatorType', annotatorType)
  },
  setDisplay({ commit }, display) {
    commit('setDisplay', display)
  },
  setDeveloper({ commit }, developer) {
    commit('setDisplay', developer)
  },
  setProgress({ commit }, progressInfo) {
    if (typeof progressInfo === 'boolean') {
      commit('setProgress', {
        progressInfo,
        message: ''
      })
    } else {
      const { isProgress, message } = progressInfo
      commit('setProgress', {
        isProgress,
        message
      })
    }
  },
  setSelectedActTool({ commit }, tool) {
    commit('setSelectedActTool', tool)
  },
  resetSelectedActTool({ commit }) {
    commit('resetSelectedActTool')
  },
  setSelectedToggleTool({ commit }, tool) {
    commit('setSelectedToggleTool', tool)
  },
  setCurrentSourceId({ commit }, id) {
    commit('setCurrentSourceId', id)
  },
  setPanels({ commit }, { projectId, panels, annotatorMode }) {
    commit('setPanels', {
      projectId,
      panels,
      annotatorMode
    })
  },
  setWindowPanels({ commit }, { projectId, windowPanels, annotatorMode }) {
    commit('setWindowPanels', {
      projectId,
      windowPanels,
      annotatorMode
    })
  },
  setVerticalPanels({ commit }, { projectId, verticalPanels, annotatorMode }) {
    commit('setVerticalPanels', {
      projectId,
      verticalPanels,
      annotatorMode
    })
  },
  setHorizontalPanels({ commit }, { projectId, horizontalPanels, annotatorMode }) {
    commit('setHorizontalPanels', {
      projectId,
      horizontalPanels,
      annotatorMode
    })
  },
  setToolList({ commit }, toolList) {
    commit('setToolList', toolList)
  },
  setToolPosition({ commit }, toolPosition) {
    commit('setToolPosition', toolPosition)
  },
  setShortcutList({ commit }, shortcutList) {
    commit('setShortcutList', shortcutList)
  },
  setHeader({ commit }, header) {
    commit('setHeader', header)
  },
  setPreferences({ commit }, preferences) {
    commit('setPreferences', preferences)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
