<template>
  <div :class="`formulate-input-element formulate-input-element--${context.class}`" :data-type="context.type">
    <div>
      <b-form-tags
        v-model="model"
        :placeholder="$t('dashboard.term.addTag') + '...'"
        separator=";"
        @tag-state="onTagState">
        <template #default="{ tags, inputAttrs, inputHandlers, addTag, removeTag }">
          <div>
            <ul class="b-form-tags-list list-unstyled mb-0 d-flex flex-wrap align-items-center">
              <li v-for="tag in tags" :key="tag" class="b-form-tag">
                <span
                  :class="[
                    errorTag.includes(tag) ? 'badge-danger' : 'badge-secondary',
                    'badge b-form-tag d-inline-flex mw-100'
                  ]">
                  <input
                    :class="[
                      errorTag.includes(tag) ? 'badge-danger' : 'badge-secondary',
                      'm-0 b-form-tag-content text-truncate b-form-tag tag-edit-input'
                    ]"
                    :value="tag"
                    type="text"
                    @change="changeTag"
                    @keydown.enter.prevent="changeTag" />
                  <button
                    aria-label="Remove tag"
                    class="close b-form-tag-remove"
                    type="button"
                    @click.stop="removeTag(tag)">
                    ×
                  </button>
                </span>
              </li>
              <li class="b-from-tags-field flex-grow-1">
                <div class="d-flex" role="group">
                  <input
                    class="shadow-none b-form-tags-input w-100 flex-grow-1 p-0 m-0 bg-transparent border-0"
                    type="text"
                    v-bind="inputAttrs"
                    value=""
                    v-on="inputHandlers" />
                  <button
                    v-if="validTag"
                    class="btn b-form-tags-button py-0 btn-outline-secondary"
                    style="font-size: 90%"
                    type="button"
                    @click.stop="addTag()">
                    Add
                  </button>
                </div>
              </li>
            </ul>
            <div v-if="tags.length > 0 && duplicatedTag" role="group">
              <small class="form-text text-muted">
                {{ `${$t('dashboard.term.duplicateTags')}: ${duplicatedTag}` }}
              </small>
            </div>
            <button
              v-if="tags.length > 0"
              class="btn b-form-tags-button btn-outline-dark remove-all-tags-btn py-0"
              type="button"
              @click.stop="removeAllTags()">
              {{ $t('dashboard.term.removeAll') }}
            </button>
          </div>
        </template>
      </b-form-tags>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'

export default {
  name: 'tag-input',
  props: ['context'],

  setup(props) {
    const model = ref(null)
    const validTag = ref(null)
    const errorTag = ref([])
    const duplicatedTag = ref(null)

    watch(
      computed(() => props.context.slotProps.errors.errorText),
      () => {
        if (props.context.slotProps.errors.errorText.length > 0) {
          props.context.slotProps.errors.errorText.map((data) => {
            errorTag.value.push(data.user)
          })
        }
      }
    )
    watch(model, (v) => {
      // eslint-disable-next-line vue/no-mutating-props
      props.context.model = v
      validTag.value = null
      errorTag.value = []
      // eslint-disable-next-line vue/no-mutating-props
      props.context.slotProps.errors.errorText = []
    })

    const onTagState = (valid, invalid, duplicate) => {
      if (valid) {
        validTag.value = valid[0]
      }
      if (duplicate) {
        duplicatedTag.value = duplicate[0]
      }
    }

    const changeTag = (e) => {
      const oldValue = e.target._value
      const newValue = e.target.value

      model.value = model.value.map((data) => {
        if (data === oldValue) {
          return newValue
        }
        return data
      })
    }

    const removeAllTags = () => {
      model.value = []
    }

    return {
      model,
      onTagState,
      validTag,
      duplicatedTag,
      errorTag,
      changeTag,
      removeAllTags
    }
  }
}
</script>

<style scoped>
.b-form-tags-input {
  outline: 0 !important;
  appearance: none;
  box-shadow: none !important;
  min-width: 5rem;
}

.tag-edit-input {
  border: none;
  color: white;
  visibility: visible;
  width: 200px;
}

.tag-edit-input:focus {
  outline: none;
}

.custom-tag-filter .tag-edit-input {
  width: 50px;
}

.remove-all-tags-btn {
  font-size: 60%;
}
</style>
