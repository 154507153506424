import { AuthToken } from '@/app/shared/auth/auth-token'
import { WorkspaceToken } from '@/app/shared/auth/workspace-token'

export class Auth {
  static isLoggedIn() {
    if (AuthToken.get()) {
      return true
    } else {
      return false
    }
  }

  static isWorkspaceIn() {
    if (WorkspaceToken.get()) {
      return true
    } else {
      return false
    }
  }
}
