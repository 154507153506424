let inMemoryToken = null

export class AuthToken {
  static get() {
    return inMemoryToken || localStorage.getItem('token') || null
  }

  static set(token, rememberMe) {
    if (rememberMe) {
      localStorage.setItem('token', token || '')
    } else {
      inMemoryToken = token
      localStorage.setItem('token', '')
    }
    if (!token) {
      localStorage.setItem('user', null)
    }
  }

  static applyFromLocationUrlIfExists() {
    const url = new URL(window.location.href)
    const authToken = url.searchParams.get('token')

    if (!authToken) {
      return
    }

    this.set(authToken, true)

    url.searchParams.delete('token')

    window.history.replaceState({}, document.title, url.toString())
  }
}
