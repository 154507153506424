import { IS_VISIBLE_LABEL_PROPERTIES } from '../constants'

const state = () => ({
  preferences: {
    general: {
      turnHeightOnCollapsed: 400,
      showLabelProperties: IS_VISIBLE_LABEL_PROPERTIES.DO_NOT_SHOW
    }
  },
  toolbar: {
    useMarkdownYn: true,
    useLatexYn: true
  }
})

const getters = {
  useMarkdownYn(state) {
    return state.toolbar.useMarkdownYn
  },
  useLatexYn(state) {
    return state.toolbar.useLatexYn
  },
  turnHeightOnCollapsed(state) {
    return state.preferences.general.turnHeightOnCollapsed
  },
  showLabelProperties(state) {
    return state.preferences.general.showLabelProperties
  }
}
const actions = {
  setUseMarkdownYn({ commit }, payload) {
    commit('setUseMarkdownYn', payload)
  },
  setUseLatexYn({ commit }, payload) {
    commit('setUseLatexYn', payload)
  },
  setTurnHeightOnCollapsed({ commit }, payload) {
    commit('setTurnHeightOnCollapsed', payload)
  },
  setShowLabelProperties({ commit }, payload) {
    commit('setShowLabelProperties', payload)
  }
}

const mutations = {
  setUseMarkdownYn(state, payload) {
    state.toolbar.useMarkdownYn = payload
  },
  setUseLatexYn(state, payload) {
    state.toolbar.useLatexYn = payload
  },
  setTurnHeightOnCollapsed(state, payload) {
    state.preferences.general.turnHeightOnCollapsed = payload
  },
  setShowLabelProperties(state, payload) {
    state.preferences.general.showLabelProperties = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
