<template>
  <div class="d-flex align-items-center bg-auth border-top border-top-2 border-primary h-100 custom-auth-layout">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-12 col-md-6 offset-xl-2 offset-md-1 order-md-2 mb-5 mb-md-0">
          <div class="text-center">
            <img alt="..." class="img-fluid" src="@/assets/img/logo.svg" />
          </div>
        </div>
        <div class="col-12 col-md-5 col-xl-4 order-md-1 my-5">
          <router-view />
          <div class="dropdown-divider"></div>
          <div class="small text-muted text-center mt-3 language">
            <ul class="language">
              <li @click="changeLocale('ko')">한국어</li>
              <li @click="changeLocale('en')">ENGLISH</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from '@/app/core/lib/util'

export default {
  name: 'auth-layout',
  setup() {
    const { i18n } = useI18n()
    const changeLocale = (code) => {
      localStorage.setItem('locale', code)
      i18n.locale = code
    }
    return {
      changeLocale
    }
  }
}
</script>

<style lang="scss" scoped>
ul.language {
  text-decoration: none;
  list-style: none;
  padding: 0;

  li {
    display: inline-block;
    margin-left: 0.3rem;
    cursor: pointer;

    &:hover {
      color: #2c7be5;
    }

    &:after {
      content: ' | ';
    }

    &:last-child:after {
      content: '';
    }
  }
}

.custom-auth-layout {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
</style>
