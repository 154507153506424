<template>
  <transition>
    <router-view />
  </transition>
</template>

<script>
import { computed, watch } from '@vue/composition-api'
import { useToast, useI18n } from '@/app/core/lib/util'
import { useErrorStore } from '@/app/shared/store/error'

export default {
  setup() {
    const { toast } = useToast()
    const { t } = useI18n()
    const { store } = useErrorStore()
    const occurredError = computed(() => store.getters.isError)

    watch(occurredError, (newV) => {
      if (newV) {
        toast.error(t('errorMessage'), { position: 'top', duration: 0 })
      }
    })
  }
}
</script>

<style>
@import '@fortawesome/fontawesome-free/css/all.css';
</style>
