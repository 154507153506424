<template>
  <div :class="`formulate-input-element formulate-input-element--${context.class}`" :data-type="context.type">
    <input
      v-model="model"
      :placeholder="context.attributes.placeholder"
      autocomplete="new-password"
      class="form-control"
      type="password" />
    <div class="form-text text-muted" style="font-size: 0.8rem" v-html="context.slotProps.help.helpText" />
  </div>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'

export default {
  name: 'custom-help-input',
  props: ['context'],
  setup(props) {
    const model = ref(null)
    watch(model, (v) => {
      props.context.model = v
    })

    watch(
      computed(() => props.context.model),
      (v, old) => {
        if (!old) {
          model.value = v
        }
      }
    )

    return {
      model
    }
  }
}
</script>
