<template>
  <div class="card">
    <table class="table table-sm table-nowrap card-table">
      <thead>
        <tr>
          <th scope="col">검수 시작일</th>
          <th scope="col">검수 종료일</th>
          <th class="col-md-3" scope="col">카테고리</th>
          <th scope="col">수량</th>
          <th>
            <button class="btn btn-sm btn-white">추가</button>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <b-form-input aria-label="검수 시작일" class="form-control" type="date" />
          </td>
          <td>
            <b-form-input aria-label="검수 시작일" class="form-control" type="date" />
          </td>
          <td>
            <div class="input-group">
              <select class="custom-select">
                <option value="action">action</option>
                <option value="label">label</option>
                <option value="object">object</option>
              </select>
            </div>
          </td>
          <td>
            <b-form-input aria-label="name" class="form-control" type="number" />
          </td>
          <td>
            <a
              aria-expanded="false"
              aria-haspopup="true"
              class="dropdown-ellipses dropdown-toggle"
              data-bs-toggle="dropdown"
              href="javascript:void(0)"
              role="button">
              <i class="fe fe-trash"></i>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'allocation-input',
  setup() {}
}
</script>
