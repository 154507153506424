const authRoutes = [
  {
    path: 'login',
    name: 'login',
    component: () => import('@/app/dashboard/views/auth/login'),
    meta: {
      requiresUserTag: false
    }
  },
  {
    path: 'register',
    name: 'register',
    component: () => import('@/app/dashboard/views/auth/register')
  },
  {
    path: 'register/confirm',
    name: 'register_confirm',
    component: () => import('@/app/dashboard/views/auth/register-confirm')
  },
  {
    path: 'password_reset',
    name: 'password_reset',
    component: () => import('@/app/dashboard/views/auth/password-reset')
  },
  {
    path: 'password_reset/:uid/:token',
    name: 'password_reset_confirm',
    component: () => import('@/app/dashboard/views/auth/password-reset-confirm')
  },
  {
    path: 'password_reset/done',
    name: 'password_reset_done',
    component: () => import('@/app/dashboard/views/auth/password-reset-done')
  },
  {
    path: 'email_reset/:uid/:token',
    name: 'setting-email-confirm',
    component: () => import('@/app/dashboard/views/auth/email-reset-confirm')
  },
  {
    path: 'email_reset/done',
    name: 'email_reset_done',
    component: () => import('@/app/dashboard/views/auth/email-reset-done')
  },
  {
    path: 'user_verification',
    name: 'user_verification',
    component: () => import('@/app/dashboard/views/auth/user-verification'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'user_waiting_approval',
    name: 'user_waiting_approval',
    component: () => import('@/app/dashboard/views/auth/user-waiting-approval'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'user_tag_setting',
    name: 'user_tag_setting',
    component: () => import('@/app/dashboard/views/auth/user-tag-setting'),
    meta: {
      requiresAuth: true,
      requiresUserTag: false
    }
  }
]

export default authRoutes
