<template>
  <div :class="`formulate-input-element formulate-input-element--${context.class}`" :data-type="context.type">
    <div class="file-input-wrapper">
      <input
        id="file-input"
        class="d-none"
        type="file"
        :accept="context.slotProps.label.accept"
        @change="changeFileContent" />
      <div class="file-content-left">
        <label for="file-input" class="file-button">{{ t('selectFile') }}</label>
        <span>{{ fileName ? fileName : fileNamePlaceholder }}</span>
      </div>
      <b-button v-if="model" type="button" variant="danger" size="sm" class="mr-2" @click="clearCurrentFile">
        <i class="fe fe-trash"></i>
        {{ $t('delete') }}
      </b-button>
    </div>
    <div v-if="attachment" class="attachment">
      <a :download="attachment.split('/')[attachment.split('/').length - 1]" :href="attachment">
        {{ attachment.split('/')[attachment.split('/').length - 1] }}
      </a>
      <a
        :download="attachment.split('/')[attachment.split('/').length - 1]"
        :href="attachment"
        class="btn btn-secondary btn-sm ml-3">
        <i class="fe fe-download"></i>
        {{ $t('dashboard.term.download') }}
      </a>
      <button type="button" class="btn btn-danger btn-sm ml-2" @click="onDelete">
        <i class="fe fe-trash"></i>
        {{ $t('delete') }}
      </button>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'
import { useI18n } from '@/app/core/lib/util'

export default {
  name: 'custom-file-input',
  props: ['context'],
  setup(props) {
    const model = ref(null)
    const { t, i18n } = useI18n({ useScope: 'local' })
    const locale = computed(() => i18n.locale)
    const fileName = ref('')
    const fileNamePlaceholder = computed(() => t('noFileSelected'))
    watch(model, (v) => {
      props.context.model = v
      if (v === null || v === undefined) {
        props.context.slotProps.label.formFile = ''
      }
    })

    watch(locale, () => {
      if (model.value) return false
      fileName.value = t('noFileSelected')
    })

    watch(
      computed(() => props.context.model),
      (v, old) => {
        if (!old) {
          model.value = v
        }
      }
    )

    const clearCurrentFile = () => {
      model.value = null
      fileName.value = ''
    }

    const changeFileContent = (e) => {
      if (e?.target?.files) {
        model.value = e.target.files[0]
        fileName.value = e.target.files[0].name
      } else {
        fileName.value = ''
      }
    }

    const attachment = computed(() => props.context.slotProps.label.formFile)

    const onDelete = () => {
      if (attachment.value) {
        model.value = null
        props.context.slotProps.label.deleteFile()
      }
    }

    return {
      model,
      attachment,
      onDelete,
      changeFileContent,
      fileName,
      t,
      fileNamePlaceholder,
      clearCurrentFile
    }
  }
}
</script>

<style lang="scss">
.attachment {
  border: 1px solid #eee;
  padding: 10px;
  border-radius: 5px;
}

.file-input-wrapper {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  border: 1px solid gray;
  border-radius: 5px;

  .file-content-left {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .file-button {
    background-color: lightslategray;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    margin: 0;
  }
}
</style>

<i18n>
{
  "ko": {
    "selectFile": "파일 선택",
    "noFileSelected": "선택된 파일 없음"
  },
  "en": {
    "selectFile": "Select file",
    "noFileSelected": "No file selected"
  }
}
</i18n>
