import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import audioAnnotator from './annotator/annotators/audio-annotator/store/audio-annotator'
import imageAnnotator from './annotator/annotators/image-annotator/store/image-annotator'
import pcdAnnotator from './annotator/annotators/pcd-annotator/store/pcd-annotator'
import promptAnnotator from './annotator/annotators/prompt-annotator/store/prompt-annotator'
import textAnnotator from './annotator/annotators/text-annotator/store/text-annotator'
import annotator from './annotator/store/annotator'
import dashboard from './dashboard/store/dashboard'

Vue.use(Vuex)

// eslint-disable-next-line import/no-named-as-default-member
const store = new Vuex.Store({
  plugins: [createPersistedState({})],
  modules: {
    dashboard,
    annotator,
    imageAnnotator,
    audioAnnotator,
    pcdAnnotator,
    textAnnotator,
    promptAnnotator
  }
})

export const useStore = () => {
  return { $store: store }
}

export default store
