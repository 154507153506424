<template>
  <div>
    <b-button :disabled="model !== ''" class="w-100" variant="primary" @click="submit">
      {{ model !== '' ? $t('dashboard.term.phoneVerificationConfirm') : $t('dashboard.term.phoneVerification') }}
    </b-button>
    <div v-if="error" class="list-unstyled text-danger">
      <li class="formulate-input-error">
        {{ error }}
      </li>
    </div>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { loadScript } from 'vue-plugin-load-script'
import { useI18n } from '@/app/core/lib/util'

loadScript('/cdn/jquery/jquery-1.12.4.min.js')
loadScript('/cdn/iamport/iamport.payment-1.1.8.js')

export default {
  name: 'register',
  props: {
    context: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const { t } = useI18n()
    const error = ref('')
    const model = ref('')

    watch(model, (v) => {
      props.context.model = v
    })

    const submit = () => {
      error.value = ''
      if (props.context.slotProps.label.form) {
        const form = props.context.slotProps.label.form.value
        if (
          form.email_and_code &&
          form.email_and_code.code &&
          form.email_and_code.email &&
          form.password &&
          form.is_agreed_1 &&
          form.is_agreed_2
        ) {
          certification()
        } else {
          error.value = t('dashboard.auth.user_verification.msg1')
        }
      } else {
        certification()
      }
    }

    const certification = () => {
      const { IMP } = window
      IMP.init('imp45576512')
      IMP.certification(
        {
          // param
          merchant_uid: 'ORD20180131-0000011', // 주문 번호
          m_redirect_url: '', // 모바일환경에서 popup:false(기본값) 인 경우 필수, 예: https://www.myservice.com/payments/complete/mobile
          popup: false // PC환경에서는 popup 파라메터가 무시되고 항상 true 로 적용됨
        },
        (rsp) => {
          // callback
          if (rsp.success) {
            model.value = rsp.imp_uid
            error.value = ''
          } else {
            model.value = ''
            error.value = rsp.error_msg
          }
        }
      )
    }

    return {
      model,
      submit,
      error
    }
  }
}
</script>
