<template>
  <div
    v-show="isVisible === 'true'"
    :class="`formulate-input-element formulate-input-element--${context.class}`"
    :data-type="context.type">
    <div class="list-group list-group-flush custom-border">
      <div class="list-group-item" style="padding-top: 0; padding-bottom: 0">
        <div class="card" style="margin: 0">
          <div v-b-toggle="`collapse-${context.slotProps.label.skipType}`" class="card-header" style="padding: 10px">
            <div class="row">
              <div class="col-auto">
                <div class="avatar avatar-sm">
                  <div class="avatar-title font-size-lg bg-secondary-soft rounded-circle text-primary">
                    <i class="fe fe-chevron-down when-open"></i>
                    <i class="fe fe-chevron-right when-closed"></i>
                  </div>
                </div>
              </div>
              <div class="col ml-n2">
                <h4 style="margin-top: 12px">
                  {{
                    context.slotProps.label.skipType === 'labeler'
                      ? $t('dashboard.term.labelerSkipOption')
                      : $t('dashboard.term.reviewerSkipOption')
                  }}
                </h4>
              </div>
            </div>
          </div>
          <b-collapse :id="`collapse-${context.slotProps.label.skipType}`" class="mt-2">
            <div :id="`collapse-${context.slotProps.label.skipType}`" class="card-body">
              <FormulateForm v-model="model" :schema="schema" />
            </div>
          </b-collapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'
import { useI18n } from '@/app/core/lib/util'

export default {
  name: 'skip-input',
  props: ['context'],
  setup(props) {
    const skipForm = ref(null)
    const model = ref({})
    watch(model, (v) => {
      props.context.model = v
    })

    watch(
      computed(() => props.context.model),
      (v) => {
        if (v) {
          model.value = v
        }
      }
    )

    const isVisible = ref(false)

    watch(
      computed(() => props.context.slotProps.label.canSkip),
      (v) => {
        isVisible.value = v
        if (props.context.slotProps.label.skipType === 'labeler') {
          model.value.can_skip_labeler = v
        } else if (props.context.slotProps.label.skipType === 'reviewer') {
          model.value.can_skip_reviewer = v
        }
      }
    )

    const { t } = useI18n()

    const schema = computed(() => {
      return [
        {
          type: 'select',
          name: `can_skip_${props.context.slotProps.label.skipType}`,
          label: t('dashboard.term.skip'),
          options: [
            {
              label: t('dashboard.term.disable'),
              value: 'false',
              is_default: true
            },
            {
              label: t('dashboard.term.able'),
              value: 'true'
            }
          ]
        },
        {
          type: 'select',
          name: `level_skip_${props.context.slotProps.label.skipType}`,
          label: t('dashboard.term.skipUnit'),
          options: [
            {
              label: t('dashboard.term.single'),
              value: 0,
              is_default: true
            },
            {
              label: t('dashboard.term.groupStep1'),
              value: 1
            },
            {
              label: t('dashboard.term.groupStep2'),
              value: 2
            }
          ]
        },
        {
          type: 'number',
          name: `count_max_skippable_${props.context.slotProps.label.skipType}`,
          label: t('dashboard.term.maxSkipCount')
        },
        {
          type: 'text',
          name: `timeout_skip_${props.context.slotProps.label.skipType}`,
          label: t('dashboard.term.skipExpiryTime'),
          help: 'format) dd hh:mm:ss, ex) 01: 05:10:00'
        }
      ]
    })

    return {
      skipForm,
      schema,
      model,
      isVisible
    }
  }
}
</script>

<style lang="scss" scoped>
.collapsed .when-open,
.not-collapsed .when-closed {
  display: none;
}
</style>
