import AuthLayout from '@/app/dashboard/layouts/auth-layout'
import BaseLayout from '@/app/dashboard/layouts/base-layout'
import DashboardLayout from '@/app/dashboard/layouts/dashboard-layout'
import PagesLayout from '@/app/dashboard/layouts/pages-layout'
import adminRoutes from '@/app/dashboard/routes/admin'
import authRoutes from '@/app/dashboard/routes/auth'
import labelerRoutes from '@/app/dashboard/routes/labeler'
import pagesRoutes from '@/app/dashboard/routes/pages'
import reviewerRoutes from '@/app/dashboard/routes/reviewer'

const dashboardRoutes = [
  {
    path: '',
    component: BaseLayout,
    redirect: { name: 'dashboard-project-list' },
    children: [
      {
        path: 'admin',
        component: DashboardLayout,
        children: [...adminRoutes]
      },
      {
        path: 'labeler',
        component: DashboardLayout,
        children: [...labelerRoutes]
      },
      {
        path: 'reviewer',
        component: DashboardLayout,
        children: [...reviewerRoutes]
      },
      {
        path: 'auth',
        component: AuthLayout,
        children: [...authRoutes]
      },
      {
        path: 'pages',
        component: PagesLayout,
        children: [...pagesRoutes]
      }
    ]
  }
]

export default dashboardRoutes
