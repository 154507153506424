const state = () => ({
  refreshKey: 0
})

const getters = {
  refreshKey(state) {
    return state.refreshKey
  }
}

// mutations
const mutations = {
  refreshDashboard(state) {
    state.refreshKey = state.refreshKey + 1
  }
}

// actions
const actions = {
  refreshDashboard({ commit }) {
    commit('refreshDashboard')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
