<template>
  <div>
    <div>
      <FormulateInput
        v-model.number="datasetForm.projectId"
        :endpoint="`projects/?category=${plugin.meta.annotation_category}&annotation_type=${plugin.meta.annotation_type}`"
        :label="$t('dashboard.term.datasetProjectDesc')"
        :search-on-focus="true"
        option-label="title"
        option-value="id"
        type="ajax_select" />
    </div>
    <div>
      <div class="table-responsive">
        <table class="table table-sm table-hover table-nowrap card-table">
          <colgroup>
            <col style="width: 10%" />
            <col style="width: 70%" />
            <col style="width: 20%" />
          </colgroup>
          <tbody class="list font-size-base">
            <tr>
              <td>
                <h3>
                  <b-badge variant="success">train</b-badge>
                </h3>
              </td>
              <td>
                <input
                  v-model.number="datasetForm.train"
                  class="w-100"
                  list="steplist"
                  max="100"
                  min="0"
                  step="1"
                  type="range" />
                <datalist id="steplist">
                  <option>0</option>
                  <option>10</option>
                  <option>20</option>
                  <option>30</option>
                  <option>40</option>
                  <option>50</option>
                  <option>60</option>
                  <option>70</option>
                  <option>80</option>
                  <option>90</option>
                  <option>100</option>
                </datalist>
              </td>
              <td>
                <b-form-spinbutton v-model.number="datasetForm.train" class="text-center" max="100" min="0" step="1" />
              </td>
            </tr>
            <tr>
              <td>
                <h3>
                  <b-badge variant="primary">val</b-badge>
                </h3>
              </td>
              <td>
                <input
                  v-model.number="datasetForm.val"
                  class="w-100"
                  list="steplist"
                  max="100"
                  min="0"
                  step="1"
                  type="range" />
              </td>
              <td>
                <b-form-spinbutton v-model.number="datasetForm.val" class="text-center" max="100" min="0" step="1" />
              </td>
            </tr>
            <tr>
              <td>
                <h3>
                  <b-badge variant="light">test</b-badge>
                </h3>
              </td>
              <td>
                <input
                  v-model.number="datasetForm.test"
                  class="w-100"
                  list="steplist"
                  max="100"
                  min="0"
                  step="1"
                  type="range" />
              </td>
              <td>
                <b-form-spinbutton v-model.number="datasetForm.test" class="text-center" max="100" min="0" step="1" />
              </td>
            </tr>
            <tr>
              <td>
                <h3>
                  <b-badge variant="secondary">total</b-badge>
                </h3>
              </td>
              <td>
                {{ total }}
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive, watch } from '@vue/composition-api'
import { useRouter } from '@/app/core/lib/util'
import PluginService from '@/app/dashboard/services/plugin-service'

export default {
  props: {
    context: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const datasetForm = reactive({
      projectId: '',
      train: 80,
      val: 10,
      test: 10
    })

    const plugin = reactive({
      meta: {
        annotation_category: '',
        annotation_type: ''
      }
    })
    const { route } = useRouter()

    onMounted(async () => {
      const results = await PluginService.getPlugin(route.value.params.neural_net_code)
      plugin.meta = { ...results.meta }
      props.context.model = datasetForm
    })

    watch(datasetForm, (v) => {
      props.context.model = v
    })

    watch(
      computed(() => datasetForm.train),
      (v) => {
        if (v < 51) {
          datasetForm.train = 50
        } else if (v > 95) {
          datasetForm.train = 95
        }

        const difference = 100 - datasetForm.train - datasetForm.test
        if (difference > 4) {
          datasetForm.val = difference
        } else {
          datasetForm.test += difference - 5
        }
      }
    )

    watch(
      computed(() => datasetForm.val),
      (v) => {
        if (v < 5) {
          datasetForm.val = 5
        } else if (v > 50) {
          datasetForm.val = 50
        }

        const difference = 100 - datasetForm.train - datasetForm.val
        if (difference > -1) {
          datasetForm.test = difference
        } else {
          datasetForm.val = 100 - datasetForm.train
        }
      }
    )

    watch(
      computed(() => datasetForm.test),
      (v) => {
        if (v > 45) {
          datasetForm.test = 45
        }

        const difference = 100 - datasetForm.train - datasetForm.test
        if (difference > -1) {
          datasetForm.val = difference
        } else {
          datasetForm.test = 100 - datasetForm.train
        }
      }
    )

    const total = computed(() => {
      return datasetForm.train + datasetForm.val + datasetForm.test
    })

    return {
      total,
      datasetForm,
      plugin
    }
  }
}
</script>
