let inMemoryToken = null

export class WorkspaceToken {
  static get() {
    return inMemoryToken || localStorage.getItem('workspace') || null
  }

  static set(token, rememberMe) {
    if (rememberMe) {
      localStorage.setItem('workspace', token || '')
    } else {
      inMemoryToken = token
      localStorage.setItem('workspace', '')
    }
  }

  static applyFromLocationUrlIfExists() {
    const url = new URL(window.location.href)
    const authToken = url.searchParams.get('workspace')

    if (!authToken) {
      return
    }

    this.set(authToken, true)

    url.searchParams.delete('workspace')

    window.history.replaceState({}, document.title, url.toString())
  }
}
