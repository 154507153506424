<template>
  <div class="header">
    <div class="header-body">
      <div class="row align-items-center">
        <div class="col">
          <h1 class="header-title text-truncate">
            {{ title }}
          </h1>
          <p v-if="description" class="text-truncate text-muted mb-0" style="line-height: 1; margin-top: 5px">
            {{ description }}
          </p>
        </div>
        <div class="col-auto">
          <slot name="header-addon"></slot>
        </div>
      </div>
      <div v-if="$slots.nav" class="row align-items-center">
        <div class="col">
          <slot name="nav"></slot>
        </div>
      </div>
    </div>
    <div v-if="$slots.nav_sub" class="row align-items-center">
      <div class="col">
        <slot name="nav_sub"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watch } from '@vue/composition-api'
import { useRouter } from '@/app/core/lib/util'

export default {
  name: 'page-header',
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: false
    },
    description: {
      type: String,
      required: false
    }
  },
  setup() {
    const items = ref([])
    const { route } = useRouter()

    onMounted(() => {
      items.value = route.value.meta.breadCrumb
    })

    watch(
      () => route.value,
      () => {
        items.value = route.value.meta.breadCrumb
      }
    )

    return {
      items
    }
  }
}
</script>
