import { cloneDeep } from 'lodash'

let clipboard = null
export const useClipboard = () => {
  if (clipboard === null) {
    clipboard = new Clipboard()
  }

  return {
    copy: clipboard.copy.bind(clipboard),
    paste: clipboard.paste.bind(clipboard)
  }
}

export const initClipboard = () => {
  useClipboard()
}
class Clipboard {
  constructor() {
    this.clonedObject = null
    if (typeof SharedWorker !== 'undefined') {
      this.worker = new SharedWorker('/clipboard-shared-worker.js')
    } else {
      throw new Error('SharedWorker is not supported in this browser.')
    }
    const port = this.worker.port
    port.onmessage = (event) => {
      const { action, value } = event.data
      if (action === 'paste') {
        this.clonedObject = JSON.parse(value)
      }
    }
  }

  copy(obj) {
    this.worker.port.postMessage({
      action: 'copy',
      value: cloneDeep(obj)
    })
  }

  paste() {
    return this.clonedObject
  }
}
