import { OPTION, SEGMENTATION, VIEW_MODE_CODE } from '@/app/annotator/annotators/pcd-annotator/utils/constant'

const state = () => ({
  preferences: {
    points: {
      size: 1,
      color: '#ffffff',
      backgroundColor: '#000000',
      renderTrigger: 0
    },
    colorPresets: [
      ['#ffffff', '#000000']
      // ['#8C2F0B', '#21D9BA'],
      // ['#FFEF42', '#2133D9'],
      // ['#79FF40', '#D921CC'],
      // ['#748C80', '#E3FFF1'],
      // ['#8C8974', '#FFFBE3'],
    ],
    selection: {
      color: '#44cc77',
      previewColor: '#44cc77',
      trackingMode: OPTION.DISABLE
    },
    cuboid: {
      fillPointsColor: OPTION.ENABLE,
      lineThickness: 2
    },
    segmentation: {
      mode: SEGMENTATION.MODE.COLOR,
      brushType: SEGMENTATION.SELECT_TYPE.CIRCLE,
      brushSize: 10,
      protect: true,
      colorMode: SEGMENTATION.COLOR_MODE.CLASS,
      defaultClassColor: '#2ddf00'
    },
    projection: {
      imageBrightness: 1,
      pointBrightness: 1,
      visible: OPTION.DISABLE,
      viewType: 'single',
      showAnnotations: 'all',
      showPoints: OPTION.DISABLE
    },
    roi: {
      useRadius: OPTION.DISABLE,
      useAov: OPTION.DISABLE,
      visible: OPTION.DISABLE,
      radius: 120,
      aov: 120
    },
    viewMode: VIEW_MODE_CODE.ALL_POINTS,
    selectedView: null,
    roiBox: {
      visible: OPTION.DISABLE
    }
  }
})

const getters = {
  roiBoxVisible(state, getters) {
    return getters.preferences.roiBox.visible
  },
  preferences(state) {
    return state.preferences
  },
  projection(state, getters) {
    return getters.preferences.projection
  },
  points(state, getters) {
    return getters.preferences.points
  },
  colorPresets(state, getters) {
    return getters.preferences.colorPresets
  },
  selection(state, getters) {
    return getters.preferences.selection
  },
  cuboid(state, getters) {
    return getters.preferences.cuboid
  },
  segmentation(state, getters) {
    return getters.preferences.segmentation
  },
  viewMode(state, getters) {
    return getters.preferences.viewMode
  }
}
const actions = {
  setPreference({ commit }, payload) {
    commit('setPreference', payload)
  },
  setSegmentation({ commit }, payload) {
    commit('setSegmentation', payload)
  },
  setBrushSizeUp({ commit }) {
    commit('setBrushSizeUp')
  },
  setBrushSizeDown({ commit }) {
    commit('setBrushSizeDown')
  },
  setViewMode({ commit }, payload) {
    commit('setViewMode', payload)
  },
  setColors({ commit }, payload) {
    commit('setColors', [...payload])
  },
  setSelectedView({ commit }, payload) {
    commit('setSelectedView', payload)
  },
  setCuboid({ commit }, payload) {
    commit('setCuboid', payload)
  },
  setRoiBoxVisible({ commit }, payload) {
    commit('setRoiBoxVisible', payload)
  }
}

const mutations = {
  setRoiBoxVisible(state, payload) {
    state.preferences.roiBox.visible = payload
  },
  setPreference(state, payload) {
    state.preferences[payload.section][payload.key] = payload.value
  },
  setColors(state, payload) {
    const [pointColor, backgroundColor] = payload
    state.preferences.points.color = pointColor
    state.preferences.points.backgroundColor = backgroundColor
  },
  setSegmentation(state, payload) {
    state.preferences.segmentation[payload.key] = payload.value
  },
  setCuboid(state, payload) {
    state.preferences.cuboid[payload.key] = payload.value
  },
  setBrushSizeUp(state) {
    if (state.preferences.segmentation.brushSize < 50) {
      state.preferences.segmentation.brushSize = Number(state.preferences.segmentation.brushSize) + 1
    }
  },
  setBrushSizeDown(state) {
    if (state.preferences.segmentation.brushSize > 5) {
      state.preferences.segmentation.brushSize = Number(state.preferences.segmentation.brushSize) - 1
    }
  },
  setViewMode(state, payload) {
    state.preferences.viewMode = payload
  },
  setSelectedView(state, payload) {
    state.preferences.selectedView = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
