import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
const errorStore = new Vuex.Store({
  state: () => ({
    error: false
  }),

  getters: {
    isError: (state) => state.error
  },

  mutations: {
    changeErrorState: (state) => {
      state.error = true
    }
  },

  actions: {
    changeErrorState: ({ commit }) => {
      commit('changeErrorState')
    }
  }
})

export const useErrorStore = () => {
  return { store: errorStore }
}

export default errorStore
