const state = () => ({
  preferences: {
    general: {
      //
    }
  }
})

// getters
const getters = {
  preferences(state) {
    return state.preferences
  },
  general(state, getters) {
    return getters.preferences.general
  }
}

// actions
const actions = {
  setPreferencesGeneral({ commit }, general) {
    commit('setPreferencesGeneral', general)
  }
}

// mutations
const mutations = {
  setPreferencesGeneral(state, general) {
    state.preferences.general = { ...general }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
