<template>
  <div :class="`formulate-input-element formulate-input-element--${context.class}`" :data-type="context.type">
    <b-form-file
      v-model="file"
      :state="Boolean(file)"
      drop-placeholder="Drop file here..."
      placeholder="Choose a file or drop it here..."></b-form-file>
  </div>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'

export default {
  name: 'custom-base64-file-input',
  props: ['context'],

  setup(props) {
    const file = ref(null)
    const getBase64FromFile = (file) => {
      return new Promise((resolve, reject) => {
        if (file) {
          const reader = new FileReader()
          let base64
          reader.onload = (event) => {
            base64 = event.target.result
            resolve(base64)
          }
          try {
            reader.readAsDataURL(file)
          } catch (e) {
            reject(e)
          }
        } else {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject()
        }
      })
    }
    watch(file, async (v) => {
      // eslint-disable-next-line vue/no-mutating-props
      props.context.model = await getBase64FromFile(v)
    })

    watch(
      computed(() => props.context.model),
      (v, old) => {
        if (!old) {
          file.value = v
        }
      }
    )

    return {
      file
    }
  }
}
</script>
