<template>
  <div :class="`formulate-input-element formulate-input-element--${context.class}`" :data-type="context.type">
    <ClassificationCard
      v-for="(classification, i) in classifications"
      :key="classification.id"
      :annotation-type="i"
      :category="category"
      :class-colors="classColors"
      :data-classification-type="i"
      :classification="classification"
      :classifications="classifications"
      :schema-type="schemaType" />
  </div>
</template>

<script>
import { computed, onMounted, onUnmounted, ref, watch } from '@vue/composition-api'
import { nanoid } from 'nanoid'

const ClassificationCard = () => import('./classification/classification-card')

export default {
  name: 'classification-input',
  components: {
    ClassificationCard
  },
  props: {
    context: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const classifications = ref(null)
    const category = computed(() => props.context.slotProps.label.category)
    const schemaType = computed(() => props.context.slotProps.label.schemaType)
    const annotationType = computed(() => props.context.slotProps.label.annotationType)
    const classColors = ref([])
    let watchers = []

    function initializeClassifications() {
      if (!classifications.value) {
        classifications.value = {}
      }
    }

    function updateClassifications(annotationTypes) {
      for (const key in classifications.value) {
        if (annotationTypes && !annotationTypes.includes(key)) {
          delete classifications.value[key]
          classifications.value = { ...classifications.value }
        }

        if (classifications.value[key]) {
          for (const classification of classifications.value[key].classification_schema) {
            if (classColors.value && !classColors.value.includes(classification.value)) {
              classColors.value.push(classification.value)
            }
          }
        }
      }
    }

    function addNewClassifications(annotationTypes) {
      if (annotationTypes && annotationTypes.length > 0) {
        for (const type of annotationTypes) {
          if (!Object.keys(classifications.value).includes(type)) {
            classifications.value = {
              ...classifications.value,
              [type]: {
                id: nanoid(10),
                classification_schema: []
              }
            }
          }
        }
      }
    }

    onMounted(() => {
      watchers = []
      classifications.value = props.context.model

      watchers.push(
        watch(
          () => classifications.value,
          () => {
            props.context.model = { ...classifications.value }
          },
          { deep: true }
        ),
        watch(annotationType, (newAnnotationTypes) => {
          initializeClassifications()
          updateClassifications(newAnnotationTypes)
          addNewClassifications(newAnnotationTypes)
        }),
        watch(category, () => {
          classifications.value = {}
        })
      )
    })

    onUnmounted(() => {
      watchers.forEach((unwatch) => unwatch())
    })

    return {
      classifications,
      category,
      annotationType,
      classColors,
      schemaType
    }
  }
}
</script>
