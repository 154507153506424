import { WorkspaceToken } from '@/app/shared/auth/workspace-token'
import api from '@/app/shared/axios/api'

export default class WorkspaceService {
  static async list() {
    const response = await api.get('workspaces/')
    return response.data
  }

  static async get() {
    const workspaceToken = WorkspaceToken.get()
    const response = await api.get(`workspaces/${workspaceToken}`)
    const data = response.data
    return data
  }

  static async create(name) {
    WorkspaceToken.set('', false)
    const response = await api.post('workspaces/', { name })
    return response.data
  }
}
