<template>
  <div :class="`formulate-input-element formulate-input-element--${context.class}`" :data-type="context.type">
    <ckeditor v-model="model" :config="editorConfig" :editor="editor" @ready="uploadAdapterPlugin" />
  </div>
</template>

<script>
import ClassicEditor from '@blowstack/ckeditor5-full-free-build'
import { computed, ref, watch } from '@vue/composition-api'
import '@blowstack/ckeditor5-full-free-build/build/translations/ko'
import { useI18n } from '@/app/core/lib/util'
import { AuthToken } from '@/app/shared/auth/auth-token'

export default {
  name: 'ck-editor-input',
  props: ['context'],
  setup(props) {
    const { t, i18n } = useI18n()
    const locale = computed(() => i18n.locale)
    const model = ref(`<h2>${t('dashboard.term.typeYourTitle')}</h2>`)

    watch(model, (v) => {
      props.context.model = v
    })

    watch(
      computed(() => props.context.model),
      (v, old) => {
        if (!old) {
          model.value = v
        }
      }
    )

    const editor = ClassicEditor
    const uploadAdapterPlugin = (editor) => {
      editor.plugins.get('FileRepository').createUploadAdapter = function (loader) {
        return new UploadAdapter(loader)
      }
    }
    const editorConfig = ref({
      language: locale,
      toolbar: [
        'Undo',
        'Redo',
        'RemoveFormat',
        'Heading',
        '|',
        'FontSize',
        'bold',
        'italic',
        'Underline',
        'Strikethrough',
        'FontColor',
        'FontBackgroundColor',
        '|',
        'Link',
        'alignment',
        'indent',
        'outdent',
        '|',
        'BulletedList',
        'NumberedList',
        'todoList',
        'InsertImage',
        'InsertTable',
        'Horizontalline'
      ]
    })

    return {
      model,
      editorConfig,
      editor,
      uploadAdapterPlugin
    }
  }
}

class UploadAdapter {
  constructor(loader) {
    this.loader = loader
  }

  upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          this._initRequest()
          this._initListeners(resolve, reject, file)
          this._sendRequest(file)
        })
    )
  }

  abort() {
    if (this.xhr) {
      this.xhr.abort()
    }
  }

  _initRequest() {
    const xhr = (this.xhr = new XMLHttpRequest())
    const baseUrl = process.env.VUE_APP_API_BASE_URL
    xhr.open('POST', `${baseUrl}ckeditor5/image_upload/`, true)
    xhr.setRequestHeader('Authorization', `Token ${AuthToken.get()}`)
    xhr.responseType = 'json'
  }

  _initListeners(resolve, reject, file) {
    const { xhr } = this
    const { loader } = this
    const genericErrorText = `Couldn't upload file: ${file.name}.`

    xhr.addEventListener('error', () => reject(genericErrorText))
    xhr.addEventListener('abort', () => reject())
    xhr.addEventListener('load', () => {
      const { response } = xhr
      if (!response || response.error) {
        return reject(response && response.error ? response.error.message : genericErrorText)
      }
      resolve({
        default: response.url
      })
    })

    if (xhr.upload) {
      xhr.upload.addEventListener('progress', (evt) => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total
          loader.uploaded = evt.loaded
        }
      })
    }
  }

  _sendRequest(file) {
    const data = new FormData()
    data.append('upload', file)
    this.xhr.send(data)
  }
}
</script>
