<template>
  <div :key="refreshKey" class="h-100">
    <transition>
      <router-view />
    </transition>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'

export default {
  name: 'base-layout',
  setup(props, { root: { $store } }) {
    const refreshKey = computed(() => $store.getters['dashboard/refreshKey'])
    return {
      refreshKey
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/scss/theme';
</style>
