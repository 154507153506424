<template>
  <div>
    <LoginBanner />
    <TheNavigation />
    <div :class="[userDelegator && 'mt-5', 'main-content']">
      <div class="bg-white pl-5 pt-2 pb-2 border-bottom">
        <b-breadcrumb :items="items" />
      </div>
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-12">
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watch } from '@vue/composition-api'
import { useRouter } from '@/app/core/lib/util'
import LoginBanner from '@/app/dashboard/components/shared/login-banner'
import TheNavigation from '@/app/dashboard/components/shared/the-navigation'

export default {
  name: 'dashboard-layout',
  components: {
    TheNavigation,
    LoginBanner
  },
  setup() {
    const items = ref([])
    const { route } = useRouter()
    const user = JSON.parse(localStorage.getItem('user'))
    const userDelegator = JSON.parse(localStorage.getItem('userDelegator'))

    onMounted(() => {
      items.value = route.value.meta.breadCrumb
    })

    watch(
      () => route.value,
      () => {
        items.value = route.value.meta.breadCrumb
      }
    )

    return {
      items,
      user,
      userDelegator
    }
  }
}
</script>

<style lang="scss" scoped>
.breadcrumb-item + .breadcrumb-item::before {
  height: 1.3rem;
}
</style>
