const state = () => ({
  contextMenuStatus: {
    mode: 'edit', // edit, list
    isVisible: false,
    targetEl: null
  },
  preferences: {
    general: {
      selection_mode: 'character',
      trim_mode: 'off',
      annotationTag: 'enable',
      relationArrow: 'enable',
      fontSize: 12,
      fontType: 'sans-serif'
    }
  }
})
const getters = {
  contextMenuStatus(state) {
    return state.contextMenuStatus
  },
  revisionStatus(state) {
    return state.revisionStatus
  },
  selectedAnnotationId(state) {
    return state.selectedAnnotationId
  },
  preferences(state) {
    return state.preferences
  },
  general(state, getters) {
    return getters.preferences.general
  }
}

const actions = {
  setRevisionStatus({ commit }, status) {
    commit('setRevisionStatus', status)
  },
  setContextMenuStatus({ commit }, status) {
    commit('setContextMenuStatus', status)
  },
  setSelectedAnnotationId({ commit }, selectedAnnotationId) {
    commit('setSelectedAnnotationId', selectedAnnotationId)
  },
  setPreferencesGeneral({ commit }, general) {
    commit('setPreferencesGeneral', general)
  }
}
const mutations = {
  setRevisionStatus(state, revisionStatus) {
    state.revisionStatus = revisionStatus
  },
  setContextMenuStatus(state, status) {
    state.contextMenuStatus = { ...state.contextMenuStatus, ...status }
  },
  setSelectedAnnotationId(state, selectedAnnotationId) {
    state.selectedAnnotationId = selectedAnnotationId
  },
  setPreferencesGeneral(state, general) {
    state.preferences.general = general
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
