import api from '@/app/shared/axios/api'

export default class PluginService {
  static async plugins(params = {}) {
    const response = await api.get('plugins', { params })
    return response.data
  }

  static async getPlugin(code) {
    const response = await api.get(`plugins/${code}`)
    return response.data
  }
}
