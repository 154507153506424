<template>
  <div :data-type="context.type">
    <v-select
      v-model="model"
      :filterable="false"
      :options="paginated"
      :placeholder="placeholder"
      :searchable="searchable"
      @close="onClose"
      @open="onOpen"
      @search="setKeywords">
      <template #spinner>Loading</template>
      <template #no-options="{ search, searching }">
        <template v-if="searching">
          {{ $t('dashboard.term.noSearchResultFound', { searchResult: search }) }}
        </template>
        <em v-else>{{ $t('dashboard.term.pleaseEnterKeywords') }}</em>
      </template>
      <template #list-footer>
        <li v-show="hasNextPage" ref="load" class="loader"></li>
      </template>
    </v-select>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from '@vue/composition-api'
import { useI18n } from '@/app/core/lib/util'
import api from '@/app/shared/axios/api'

export default {
  name: 'ajax-select',
  props: {
    context: {
      type: Object,
      required: true
    }
  },

  setup(props, { root: { $nextTick } }) {
    const model = ref(null)
    const load = ref()
    const data = ref(null)
    let observer
    const currentPage = ref(1)
    const keywords = ref('')
    const placeholder = ref(props.context.slotProps.label.placeholder)
    const { t } = useI18n()
    const { context } = props

    const infiniteScroll = async ([{ isIntersecting, target }]) => {
      if (isIntersecting) {
        const ul = target.offsetParent
        const { scrollTop } = target.offsetParent
        if (hasNextPage.value) {
          currentPage.value += 1
        }
        $nextTick(() => {
          setTimeout(() => {
            ul.scrollTop = scrollTop
          }, 100)
        })
      }
    }

    onMounted(() => {
      observer = new IntersectionObserver(infiniteScroll)

      model.value = props.context.model
    })

    watch(currentPage, async () => {
      await onSearch()
    })

    watch(model, (v) => {
      if (v) {
        context.model = v
        if (v.label && !v.value) {
          context.model = ''
        }
      } else {
        context.model = ''
      }
    })

    watch(
      computed(() => context.model),
      (v, old) => {
        if (!old) {
          model.value = v
          context.model = v
        }

        if (v === '' && model.value !== '') {
          model.value = ''
        }
      }
    )

    const endpoint = computed(() => {
      return props.context.slotProps.label.endpoint
    })

    const optionValue = computed(() => {
      return props.context.slotProps.label.optionValue
    })

    const optionLabel = computed(() => {
      return props.context.slotProps.label.optionLabel
    })

    const computedOption = computed(() => {
      return props.context.slotProps.label.computedOption
    })

    const searchOnFocus = computed(() => {
      return props.context.slotProps.label.searchOnFocus
    })

    const searchable = computed(() => {
      return props.context.slotProps.label.searchable
    })

    const extraOptions = computed(() => {
      return props.context.slotProps.label.extraOptions
    })

    const options = ref([])

    const paginated = computed(() => {
      return options.value
    })

    const onOpen = async () => {
      if (searchOnFocus.value === true) {
        await onSearch()
      }

      if (hasNextPage.value) {
        $nextTick(() => {
          observer.observe(load.value)
        })
      }
    }

    /**
     * Triggered when the search text changes.
     *
     * @param search  {String}    Current search text
     * @param loading {Function}  Toggle loading class
     */
    const setKeywords = async (search, loading) => {
      loading(true)
      keywords.value = search
      loading(false)
    }

    const onSearch = async () => {
      const params = {
        search: keywords.value,
        page: currentPage.value,
        page_size: 30
      }
      const response = await api.get(endpoint.value, { params })
      data.value = response.data
      setOptions()
    }

    watch(keywords, () => {
      currentPage.value = 1
      onSearch()
    })

    const setOptions = () => {
      if (data.value) {
        if (computedOption.value) {
          if (currentPage.value > 1) {
            options.value = [...options.value, ...data.value.results.map(computedOption.value)]
          } else {
            options.value = data.value.results.map(computedOption.value)
          }
        } else if (currentPage.value > 1) {
          options.value = [
            ...options.value,
            ...data.value.results.map((d) => {
              return {
                value: d[optionValue.value],
                label: d[optionLabel.value]
              }
            })
          ]
        } else {
          options.value = data.value.results.map((d) => {
            return {
              value: d[optionValue.value],
              label: d[optionLabel.value]
            }
          })
        }
      } else {
        options.value = []
      }
      if (extraOptions.value) {
        options.value = [...extraOptions.value, ...options.value]
      }
      if (!searchable.value) {
        options.value.unshift({
          label: t('dashboard.term.all'),
          value: ''
        })
      }
    }

    const hasNextPage = computed(() => data.value && data.value.next)

    const onClose = () => {
      keywords.value = ''
      currentPage.value = 1
      observer.disconnect()
    }

    return {
      model,
      setKeywords,
      options,
      onOpen,
      onClose,
      load,
      hasNextPage,
      paginated,
      placeholder,
      searchable
    }
  }
}
</script>

<style lang="scss">
.custom-select-filter .vs__search:read-only {
  background-color: white !important;
}
</style>
