<template>
  <div :data-type="context.type">
    <v-select
      v-model="model"
      :close-on-select="!isMultiple"
      :filterable="false"
      :multiple="isMultiple"
      :options="paginated"
      class="multi-chooser"
      @close="onClose"
      @open="onOpen"
      @search="setKeywords">
      <template #selected-option-container="{ option }">
        <div class="vs__selected">
          <span>
            {{ option.label }}
            <button class="vs__deselect" type="button" @click.stop="deselect(option.value)">
              <svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"></path>
              </svg>
            </button>
          </span>
        </div>
      </template>
      <template #spinner>Loading</template>
      <template #no-options="{ search, searching }">
        <template v-if="searching">
          {{ $t('dashboard.term.noSearchResultFound', { searchResult: search }) }}
        </template>
        <em v-else style="opacity: 0.5">
          {{ $t('dashboard.term.pleaseEnterKeywords') }}
        </em>
      </template>
      <template #list-footer>
        <li v-show="hasNextPage" ref="loadEl" class="loader"></li>
      </template>
    </v-select>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from '@vue/composition-api'
import api from '@/app/shared/axios/api'

export default {
  name: 'ajax-multi-select',
  props: {
    context: {
      type: Object,
      required: true
    }
  },

  setup(props, { root: { $nextTick } }) {
    const model = ref(null)
    let observer
    const hasNextPage = computed(() => data.value && data.value.next)
    const currentPage = ref(1)
    const loadEl = ref()
    const data = ref(null)
    const keywords = ref('')
    const { context } = props
    const infiniteScroll = async ([{ isIntersecting, target }]) => {
      if (isIntersecting) {
        const ul = target.offsetParent
        const { scrollTop } = target.offsetParent
        if (hasNextPage.value) {
          currentPage.value += 1
        }
        $nextTick(() => {
          setTimeout(() => {
            ul.scrollTop = scrollTop
          }, 100)
        })
      }
    }

    onMounted(() => {
      if (!context.model) {
        context.model = []
      }

      observer = new IntersectionObserver(infiniteScroll)
      if (computedOption.value && props.context.model.length > 0) {
        model.value = props.context.model.map(computedOption.value)
      } else {
        model.value = props.context.model
      }
    })

    watch(model, (v) => {
      if (v && context?.model) {
        if (isMultiple.value === true) {
          if (v.length > 0) {
            context.model = v.map((d) => d?.value ?? null)
          } else {
            context.model = v
          }
        } else {
          context.model = v
        }
      }
    })

    watch(
      computed(() => props.context.model),
      (v, old) => {
        if (!old) {
          model.value = v
          if (v.value) {
            context.model = v.value
          } else {
            context.model = v
          }
        }
        if (v === '') {
          context.model = []
        }
      }
    )

    const endpoint = computed(() => {
      return props.context?.slotProps?.label?.endpoint ?? null
    })

    const isMultiple = computed(() => {
      return props.context.slotProps.label.isMultiple
    })

    const optionValue = computed(() => {
      return props.context.slotProps.label.optionValue
    })

    const optionLabel = computed(() => {
      return props.context.slotProps.label.optionLabel
    })

    const computedOption = computed(() => {
      return props.context.slotProps.label.computedOption
    })

    const searchOnFocus = computed(() => {
      return props.context.slotProps.label.searchOnFocus
    })

    const searchOptions = computed(() => {
      return props.context.slotProps.label.searchOptions
    })

    const options = ref([])

    const paginated = computed(() => {
      return options.value
    })

    const onOpen = async () => {
      if (searchOnFocus.value === true) {
        await onSearch()
      }
      if (hasNextPage.value) {
        $nextTick(() => {
          observer.observe(loadEl.value)
        })
      }
    }

    /**
     * Triggered when the search text changes.
     *
     * @param search  {String}    Current search text
     * @param loading {Function}  Toggle loading class
     */
    const setKeywords = async (search, loading) => {
      loading(true)
      keywords.value = search
      loading(false)
    }

    const onSearch = async () => {
      if (endpoint.value) {
        const params = {
          search: keywords.value,
          page: currentPage.value,
          page_size: 30
        }
        const response = await api.get(endpoint.value, { params })
        data.value = response.data
        setOptions()
      } else {
        options.value = searchOptions.value
      }
    }

    const setOptions = () => {
      if (data.value) {
        if (computedOption.value) {
          if (currentPage.value > 1) {
            options.value = [
              ...options.value,
              ...data.value.results.map(computedOption.value).filter((d) => Boolean(d.value && d.label))
            ]
          } else {
            options.value = data.value.results.map(computedOption.value).filter((d) => Boolean(d.value && d.label))
          }
        } else if (currentPage.value > 1) {
          options.value = [
            ...options.value,
            ...data.value.results
              .map((d) => {
                return {
                  value: d[optionValue.value],
                  label: d[optionLabel.value]
                }
              })
              .filter((d) => Boolean(d.value && d.label))
          ]
        } else {
          options.value = data.value.results
            .map((d) => {
              return {
                value: d[optionValue.value],
                label: d[optionLabel.value]
              }
            })
            .filter((d) => Boolean(d.value && d.label))
        }
      } else {
        options.value = []
      }
    }

    const deselect = (value) => {
      model.value = model.value.filter((v) => v.value !== value)
    }

    const onClose = () => {
      keywords.value = ''
      currentPage.value = 1
      observer.disconnect()
    }

    watch(currentPage, async () => {
      await onSearch()
    })

    watch(keywords, () => {
      currentPage.value = 1
      onSearch()
    })

    return {
      model,
      setKeywords,
      options,
      onOpen,
      isMultiple,
      deselect,
      hasNextPage,
      onClose,
      paginated,
      loadEl
    }
  }
}
</script>

<style>
.multi-chooser .vs__search::placeholder,
.multi-chooser .vs__dropdown-toggle {
  display: block;
  width: 100%;
  height: auto;
  padding: 0;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #12263f;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #d2ddec;
  border-radius: 0.25rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.multi-chooser .vs__selected {
  background-color: #edf2f9;
  border-radius: 0.1875rem;
  display: inline-block;
  font-size: 0.8125rem;
  margin-top: 0.15rem;
  margin-right: 0.2rem;
  padding: 0.1rem 0.5rem;
  border: none;
  height: fit-content;
}

.multi-chooser .vs__clear,
.multi-chooser .vs__open-indicator {
  fill: #394066;
}
</style>
