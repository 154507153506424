<template>
  <div :class="`formulate-input-element formulate-input-element--${context.class}`" :data-type="context.type">
    <div style="display: flex">
      <b-form-checkbox v-model="model" />
      <span v-html="context.slotProps.label.labelText" />
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'

export default {
  name: 'custom-label-input',
  props: ['context'],
  setup(props) {
    const model = ref(null)
    watch(model, (v) => {
      props.context.model = v
    })

    watch(
      computed(() => props.context.model),
      (v, old) => {
        if (!old) {
          model.value = v
        }
      }
    )

    return {
      model
    }
  }
}
</script>
