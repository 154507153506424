<template>
  <div :class="`formulate-input-element formulate-input-element--${context.class}`" :data-type="context.type">
    <div class="list-group list-group-flush custom-border">
      <div class="list-group-item" style="padding-bottom: 0">
        <div class="card" style="margin: 0">
          <div v-b-toggle.collapse-1 class="card-header" style="padding: 10px">
            <div class="row">
              <div class="col-auto">
                <div class="avatar avatar-sm">
                  <div class="avatar-title font-size-lg bg-secondary-soft rounded-circle text-primary">
                    <i class="fe fe-chevron-down when-open"></i>
                    <i class="fe fe-chevron-right when-closed"></i>
                  </div>
                </div>
              </div>
              <div class="col ml-n2">
                <h4 style="margin-top: 12px">
                  {{ $t('dashboard.term.configuration') }}
                </h4>
              </div>
            </div>
          </div>
          <b-collapse id="collapse-1" class="mt-2">
            <div id="collapse-1" class="card-body">
              <v-jsoneditor v-model="model" :options="options" :plus="false"></v-jsoneditor>
            </div>
          </b-collapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'

export default {
  name: 'json-editor-input.vue',
  props: ['context'],
  setup(props) {
    const model = ref({})
    const options = ref({ modes: ['code', 'text', 'tree', 'form', 'view'] })

    watch(model, (v) => {
      props.context.model = v
    })

    watch(
      computed(() => props.context.model),
      (v, old) => {
        if (!old) {
          model.value = v
        }
      }
    )

    return {
      model,
      options
    }
  }
}
</script>

<style lang="scss" scoped>
.collapsed .when-open,
.not-collapsed .when-closed {
  display: none;
}

.custom-border {
  border-top: 1px solid #edf2f9;
}
</style>
