import Vue from 'vue'
import VueRouter from 'vue-router'
import annotatorRoutes from '@/app/annotator/routes'
import dashboardRoutes from '@/app/dashboard/routes'
import UserService from '@/app/dashboard/services/user-service'
import { Auth } from '@/app/shared/auth/auth'

Vue.use(VueRouter)

const routes = [
  ...dashboardRoutes,
  ...annotatorRoutes,
  {
    path: '/auth/error',
    name: 'Error',
    component: () => import('@/app/dashboard/views/auth/error')
  },
  {
    path: '/authentication',
    name: 'authentication',
    component: () => import('@/app/dashboard/views/auth/authentication')
  },
  {
    path: '/adblock_alert',
    name: 'adblock-alert',
    component: () => import('@/app/dashboard/views/auth/adblock-alert')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }
})

router.beforeEach(async (to, from, next) => {
  let user = null
  if (to.meta.requiresUserTag === undefined) {
    to.meta.requiresUserTag = true
  }
  if (Auth.isLoggedIn() && to.meta.requiresUserTag) {
    user = await UserService.me()
  }
  if (user !== null && to.meta.requiresUserTag && user.tags && !user.tags.includes(process.env.VUE_APP_USER_TAG)) {
    router.push({
      name: 'user_tag_setting',
      query: {
        redirect: encodeURIComponent(window.location.pathname + window.location.search)
      }
    })
  } else if (to.meta.requiresAuth && !Auth.isLoggedIn()) {
    next({ name: 'login' })
  } else if (to.meta.requiresWorkspace && !Auth.isWorkspaceIn()) {
    next({ name: 'dashboard-project-list' })
  } else {
    next()
  }
})

export default router
