import Empty from '@/app/shared/views/empty'

const annotatorRoutes = [
  {
    path: '',
    name: 'empty',
    component: Empty,
    children: [
      {
        name: 'annotator-labeler-mode',
        path: '/annotator/:project_category/:assignment_id',
        meta: {
          requiresAuth: true,
          requiresUserTag: false
        },
        component: () => import('../core/components/main')
      },
      {
        name: 'annotator-reviewer-mode',
        path: '/annotator/:project_category/:assignment_id/reviewer',
        meta: {
          requiresAuth: true,
          requiresUserTag: false
        },
        component: () => import('../core/components/main')
      },
      {
        name: 'annotator-ground-truth-mode',
        path: '/annotator/:project_category/:label_id/ground_truth',
        meta: {
          requiresAuth: true,
          requiresUserTag: false
        },
        component: () => import('../core/components/main')
      },
      {
        name: 'annotator-calculation-template-mode',
        path: '/annotator/:project_category/:label_id/calculation_template',
        meta: {
          requiresAuth: true,
          requiresUserTag: false
        },
        component: () => import('../core/components/main')
      },
      {
        name: 'annotator-manager-mode',
        path: '/annotator/:project_category/:assignment_id/manager',
        meta: {
          requiresAuth: true,
          requiresUserTag: false
        },
        component: () => import('../core/components/main')
      },
      {
        name: 'diagnosis',
        path: '/diagnosis/pcd-calib-checker',
        meta: {
          requiresAuth: true,
          requiresUserTag: false
        },
        component: () => import('../../../diagnosis/pcd-calib-checker/app')
      },
      {
        name: 'labs',
        path: '/labs/deep-zoom',
        meta: {
          requiresAuth: true,
          requiresUserTag: false
        },
        component: () => import('../../../labs/deep-zoom/app')
      }
    ]
  }
]

export default annotatorRoutes
