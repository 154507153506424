import Empty from '@/app/shared/views/empty'
import i18n from '@/i18n'

const reviewerRoutes = [
  {
    path: 'dashboard',
    component: Empty,
    children: [
      {
        path: '',
        name: 'dashboard-project-list-reviewer',
        meta: {
          requiresAuth: true,
          breadCrumb: [
            {
              text: i18n.t('dashboard.term.reviewData'),
              to: { name: 'dashboard-project-list-reviewer' }
            },
            {
              text: i18n.t('dashboard.term.dashboard'),
              to: { name: 'dashboard-project-list' }
            },
            {
              text: i18n.t('dashboard.term.projects'),
              to: { name: 'dashboard-project-list-reviewer' }
            }
          ]
        },
        component: () => import('@/app/dashboard/views/reviewer/dashboard/dashboard-project-list-reviewer')
      },
      {
        path: '',
        name: 'dashboard-project-detail-reviewer',
        component: () => import('@/app/dashboard/views/reviewer/dashboard/dashboard-project-detail-reviewer'),
        children: [
          {
            path: ':id',
            name: 'dashboard-project-detail-layout-reviewer',
            components: {
              default: () =>
                import('@/app/dashboard/views/reviewer/dashboard/dashboard-project-detail-layout-reviewer'),
              nav: () =>
                import('@/app/dashboard/views/reviewer/dashboard/partials/navigation-dashboard-detail-reviewer')
            },
            children: [
              {
                path: 'overview',
                name: 'dashboard-project-detail-overview-reviewer',
                meta: {
                  requiresAuth: true,
                  breadCrumb: [
                    {
                      text: i18n.t('dashboard.term.reviewData'),
                      to: { name: 'dashboard-project-list-reviewer' }
                    },
                    {
                      text: i18n.t('dashboard.term.dashboard'),
                      to: { name: 'dashboard-project-list-reviewer' }
                    },
                    {
                      text: i18n.t('dashboard.term.projects'),
                      to: { name: 'dashboard-project-list-reviewer' }
                    },
                    {
                      text: i18n.t('dashboard.term.reviewStatus'),
                      to: {
                        name: 'dashboard-project-detail-overview-reviewer'
                      }
                    }
                  ]
                },
                component: () =>
                  import('@/app/dashboard/views/reviewer/dashboard/dashboard-project-detail-overview-reviewer')
              },
              {
                path: 'statistics',
                name: 'dashboard-project-detail-statistics-reviewer',
                meta: {
                  requiresAuth: true,
                  breadCrumb: [
                    {
                      text: i18n.t('dashboard.term.labelData'),
                      to: { name: 'dashboard-project-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.dashboard'),
                      to: { name: 'dashboard-project-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.projects'),
                      to: { name: 'dashboard-project-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.reviewerStatistics'),
                      to: {
                        name: 'dashboard-project-detail-statistics-reviewer'
                      }
                    }
                  ]
                },
                component: () =>
                  import('@/app/dashboard/views/reviewer/dashboard/dashboard-project-detail-statistics-reviewer')
              },
              {
                path: 'guide',
                name: 'dashboard-project-detail-guide-reviewer',
                meta: {
                  requiresAuth: true,
                  breadCrumb: [
                    {
                      text: i18n.t('dashboard.term.reviewData'),
                      to: { name: 'dashboard-project-list-reviewer' }
                    },
                    {
                      text: i18n.t('dashboard.term.dashboard'),
                      to: { name: 'dashboard-project-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.projects'),
                      to: { name: 'dashboard-project-list-reviewer' }
                    },
                    {
                      text: i18n.t('dashboard.term.guideline'),
                      to: { name: 'dashboard-project-detail-guide-reviewer' }
                    }
                  ]
                },
                component: () =>
                  import('@/app/dashboard/views/reviewer/dashboard/dashboard-project-detail-guide-reviewer')
              }
              // {
              //   path: 'qna',
              //   name: 'dashboard-project-detail-qna-reviewer',
              //   meta: { requiresAuth: true },
              //   component: () => import('@/app/dashboard/views/reviewer/dashboard/dashboard-project-detail-qna-reviewer')
              // }
            ]
          }
        ]
      }
    ]
  }
]

export default reviewerRoutes
