import Empty from '@/app/shared/views/empty'
import i18n from '@/i18n'

const labelerRoutes = [
  {
    path: 'dashboard',
    component: Empty,
    children: [
      {
        path: '',
        name: 'dashboard-project-list',
        meta: {
          requiresAuth: true,
          breadCrumb: [
            {
              text: i18n.t('dashboard.term.labelData'),
              to: { name: 'dashboard-project-list' }
            },
            {
              text: i18n.t('dashboard.term.dashboard'),
              to: { name: 'dashboard-project-list' }
            },
            {
              text: i18n.t('dashboard.term.projects'),
              to: { name: 'dashboard-project-list' }
            }
          ]
        },
        component: () => import('@/app/dashboard/views/labeler/dashboard/dashboard-project-list')
      },
      {
        path: '',
        name: 'dashboard-project-detail',
        component: () => import('@/app/dashboard/views/labeler/dashboard/dashboard-project-detail'),
        children: [
          {
            path: ':id',
            name: 'dashboard-project-detail-layout',
            components: {
              default: () => import('@/app/dashboard/views/labeler/dashboard/dashboard-project-detail-layout'),
              nav: () => import('@/app/dashboard/views/labeler/dashboard/partials/navigation-dashboard-detail')
            },
            children: [
              {
                path: 'overview',
                name: 'dashboard-project-detail-overview',
                meta: {
                  requiresAuth: true,
                  breadCrumb: [
                    {
                      text: i18n.t('dashboard.term.labelData'),
                      to: { name: 'dashboard-project-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.dashboard'),
                      to: { name: 'dashboard-project-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.projects'),
                      to: { name: 'dashboard-project-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.workStatus'),
                      to: { name: 'dashboard-project-detail-overview' }
                    }
                  ]
                },
                component: () => import('@/app/dashboard/views/labeler/dashboard/dashboard-project-detail-overview')
              },
              {
                path: 'statistics',
                name: 'dashboard-project-detail-statistics',
                meta: {
                  requiresAuth: true,
                  breadCrumb: [
                    {
                      text: i18n.t('dashboard.term.labelData'),
                      to: { name: 'dashboard-project-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.dashboard'),
                      to: { name: 'dashboard-project-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.projects'),
                      to: { name: 'dashboard-project-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.labelerStatistics'),
                      to: { name: 'dashboard-project-detail-statistics' }
                    }
                  ]
                },
                component: () => import('@/app/dashboard/views/labeler/dashboard/dashboard-project-detail-statistics')
              },
              {
                path: 'guide',
                name: 'dashboard-project-detail-guide',
                meta: {
                  requiresAuth: true,
                  breadCrumb: [
                    {
                      text: i18n.t('dashboard.term.labelData'),
                      to: { name: 'dashboard-project-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.dashboard'),
                      to: { name: 'dashboard-project-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.projects'),
                      to: { name: 'dashboard-project-list' }
                    },
                    {
                      text: i18n.t('dashboard.term.guideline'),
                      to: { name: 'dashboard-project-detail-guide' }
                    }
                  ]
                },
                component: () => import('@/app/dashboard/views/labeler/dashboard/dashboard-project-detail-guide')
              }
              // {
              //   path: 'qna',
              //   name: 'dashboard-project-detail-qna',
              //   meta: { requiresAuth: true },
              //   component: () => import('@/app/dashboard/views/labeler/dashboard/dashboard-project-detail-qna')
              // }
            ]
          }
        ]
      }
    ]
  }
]

export default labelerRoutes
