import { getCurrentInstance, reactive, toRefs, watch, set } from '@vue/composition-api'
import chroma from 'chroma-js'
import hsl from 'hsl-to-hex'
import { cloneDeep, isEqual } from 'lodash'
import { nanoid } from 'nanoid'
import Vue from 'vue'
import { useEventManager } from '@/app/annotator/core/lib/event-manager'
import { useAnnotation, useAnnotationGroup } from '@/app/core/lib/data'
import i18n from '@/i18n'

const __DEV__ = process.env.NODE_ENV === 'development'

let tempLogMessages = []
export const useLog = () => {
  const getBrightRandomHexColor = () => {
    const r = Math.floor(Math.random() * 255)
    const g = Math.floor(Math.random() * 255)
    const b = Math.floor(Math.random() * 255)
    const hex = {
      r: '0' + r.toString(16),
      g: '0' + g.toString(16),
      b: '0' + b.toString(16)
    }
    const color =
      '#' +
      hex.r.substring(hex.r.length - 2, hex.length) +
      hex.g.substring(hex.g.length - 2, hex.length) +
      hex.b.substring(hex.b.length - 2, hex.length)
    const luminance = chroma(color).luminance()
    if (luminance < 0.1) {
      return getBrightRandomHexColor()
    } else {
      return color
    }
  }

  let backgroundColor = 'transparent'
  const color = getBrightRandomHexColor()

  const _log = (type, className, ...args) => {
    if (isEqual(tempLogMessages, [className, ...args])) {
      return false
    }

    const message = [
      `%c${type[0]}%c${className}`,
      type[1],
      `color: ${color};
        background-color: ${backgroundColor};
        border: 1px solid ${color};
        padding: 1px 5px;
        width: 300px;
        display: inline-block;
        border-radius: 2px;
        font-size: 0.3rem;
        `
    ]
    if (args?.length > 0) {
      args.forEach((arg) => {
        message.push(arg)
      })
    }

    __DEV__ && console.log(...message)
    tempLogMessages = [className, ...args]
  }

  const sharedStyle = `
  color:#000;
  border: 1px solid #202124;
  font-weight: bold;
  padding: 0 5px;
  margin-right: 5px;
  font-size: 0.3rem;
  `

  return {
    log: (className, ...args) => {
      _log(['INFO ', `${sharedStyle}background-color:#17b2f1;`], className, ...args)
    },
    debug: (className, ...args) => {
      _log(['DEBUG', `${sharedStyle}background-color:#8acc44;`], className, ...args)
    },
    warn: (className, ...args) => {
      _log(['WARN ', `${sharedStyle}background-color:#ffc217;`], className, ...args)
    },
    error: (className, ...args) => {
      _log(['ERROR', `${sharedStyle}background-color:#da6d1c;`], className, ...args)
    },
    fatal: (className, ...args) => {
      _log(['FATAL', `${sharedStyle}background-color:#f91314;`], className, ...args)
    }
  }
}
export const useRouter = () => {
  const vm = getCurrentInstance().proxy
  const state = reactive({
    route: vm.$route
  })

  watch(
    () => vm.$route,
    (r) => {
      state.route = r
    }
  )

  return {
    ...toRefs(state),
    router: vm.$router
  }
}

export const useI18n = () => {
  const vm = getCurrentInstance().proxy

  return {
    i18n: vm.$i18n,
    t: vm.$t.bind(vm),
    tc: vm.$tc.bind(vm),
    d: vm.$d.bind(vm),
    te: vm.$te.bind(vm),
    n: vm.$n.bind(vm)
  }
}

export const useToast = () => {
  const vm = getCurrentInstance().proxy
  return {
    toast: vm.$toast
  }
}

export const useWorker = () => {
  const vm = getCurrentInstance().proxy

  return {
    worker: vm.$worker
  }
}

export const live = (eventType, elementQuerySelector, cb) => {
  const { pushEvent } = useEventManager()
  const eventHandler = (event) => {
    const qs = document.querySelectorAll(elementQuerySelector)
    if (qs) {
      let el = event.target
      let index = -1
      while (el && (index = Array.prototype.indexOf.call(qs, el)) === -1) {
        el = el.parentElement
      }

      if (index > -1) {
        cb.call(el, event)
      }
    }
  }
  pushEvent(document, eventType, eventHandler, true)
  document.addEventListener(eventType, eventHandler, true)
}

export function setKeyBindingMap(keyBindingMap, codeStr, callback) {
  const codes = codeStr.split('|')
  for (const code of codes) {
    keyBindingMap[code] = callback
  }
}

export function watchResize(el) {
  return function (fn) {
    new ResizeObserver(fn).observe(el)
  }
}

export function addComma(num) {
  if (num) {
    const regexp = /\B(?=(\d{3})+(?!\d))/g
    return num.toString().replace(regexp, ',')
  } else {
    return '0'
  }
}

export function getLabel(variables, annotationId, toolParam, classificationParam) {
  const annotatorData = variables.annotatorData
  const classification = getClassification(variables, annotationId, classificationParam)
  const tool = getTool(variables, annotationId, toolParam)
  const locale = i18n.locale
  const result = []
  const classificationClone = cloneDeep(classification)
  const schema = annotatorData.classification[tool]?.classification_schema
  if (!schema) return result
  const idList = schema.sort
  let keys = Object.keys(classificationClone)
  for (const id of idList) {
    const obj = schema.properties[id]
    if (keys.length === 0) {
      break
    }
    if (shouldSkipCondition(obj, classificationClone, schema)) {
      continue
    }
    if (!keys.includes(obj.code)) {
      continue
    }
    processWidget(obj, classificationClone, locale, result)
    keys = keys.filter((d) => d !== obj.code)
  }
  if (result.length > 0) {
    result.splice(0, 0, {
      ...result[0],
      text: ''
    })
  }
  markRepresentativeCodes(result, variables.annotatorData.classification[tool].representativeCodes)
  return result
}

function getClassification(variables, annotationId, classificationParam) {
  if (classificationParam) {
    return classificationParam
  }
  const { annotation } = useAnnotation(variables, annotationId)
  const { annotationGroup } = useAnnotationGroup(variables, annotationId)
  return annotation?.classification || annotationGroup?.classification || { class: '' }
}

function getTool(variables, annotationId, toolParam) {
  if (toolParam) {
    return toolParam
  }
  const { annotation } = useAnnotation(variables, annotationId)
  const { annotationGroup } = useAnnotationGroup(variables, annotationId)
  return annotation.tool || annotationGroup.tool
}

function shouldSkipCondition(obj, classificationClone, schema) {
  if (obj.condition && obj.condition.$ref && obj.condition.const) {
    const arr = obj.condition.$ref.split('/')
    const c = schema.properties[arr[arr.length - 1]].code
    return classificationClone[c] !== obj.condition.const
  }
  return false
}

function processWidget(obj, classificationClone, locale, result) {
  if (['single_choice', 'single_choice--radio'].includes(obj.widget)) {
    processSingleChoice(obj, classificationClone, locale, result)
  } else if (obj.widget === 'multi_choice') {
    processMultiChoice(obj, classificationClone, locale, result)
  } else {
    processDefault(obj, classificationClone, result)
  }
}

function processSingleChoice(obj, classificationClone, locale, result) {
  const idx = obj.enum.indexOf(classificationClone[obj.code])
  if (idx > -1) {
    const name = locale === 'ko' ? obj.enumNames[idx] : obj.enum[idx]
    const color = obj.enumValues[idx]
    const r = {
      text: name,
      color: color,
      widget: obj.widget,
      code: obj.code,
      display: {
        ko: obj.enumNames[idx],
        en: obj.enum[idx]
      }
    }
    if (obj.customFields && obj.customFields.length > 0) {
      r.customFields = obj.customFields[idx]
    }
    result.push(r)
  }
}

function processMultiChoice(obj, classificationClone, locale, result) {
  const items = classificationClone[obj.code]
  if (items) {
    for (const item of items) {
      const idx = obj.enum.indexOf(item)
      if (idx > -1) {
        const name = locale === 'ko' ? obj.enumNames[idx] : obj.enum[idx]
        const color = obj.enumValues[idx]
        const r = {
          text: name,
          color: color,
          widget: obj.widget,
          code: obj.code,
          display: {
            ko: obj.enumNames[idx],
            en: obj.enum[idx]
          }
        }
        if (obj.customFields && obj.customFields.length > 0) {
          r.customFields = obj.customFields[idx]
        }
        result.push(r)
      }
    }
  }
}

function processDefault(obj, classificationClone, result) {
  if (classificationClone[obj.code]) {
    const r = {
      text: classificationClone[obj.code],
      color: '',
      widget: obj.widget,
      code: obj.code,
      display: {
        ko: classificationClone[obj.code],
        en: classificationClone[obj.code]
      }
    }
    result.push(r)
  }
}

function markRepresentativeCodes(result, representativeCodes) {
  if (representativeCodes && representativeCodes.length > 0) {
    for (const resultItem of result) {
      resultItem.isRepresentative = representativeCodes.includes(resultItem.code)
    }
  }
}

function getRepresentativeCodes(classification) {
  let representativeCodes = classification.representativeCodes
  if (!representativeCodes || representativeCodes.length === 0) {
    representativeCodes = ['class']
  }
  return representativeCodes
}

function findColorInLabel(label, representativeCodes) {
  for (const code of representativeCodes) {
    for (const obj of label) {
      if (code === obj.code) {
        return obj.color
      }
    }
  }
  return ''
}

function findClassColor(label) {
  const l = label.find((d) => d.code === 'class')
  return l ? l.color : ''
}

export function getRepresentativeColor(variables, tool, label = []) {
  const classification = variables.annotatorData.classification[tool]
  if (!classification) return ''

  const representativeCodes = getRepresentativeCodes(classification)
  let color = findColorInLabel(label, representativeCodes)

  if (!color) {
    color = findClassColor(label)
  }

  return color
}

export function getFileTypeFromUrl(url) {
  const arr = url.split('.')
  const ext = arr[arr.length - 1].toUpperCase()
  if (['JPG', 'PNG', 'JPEG'].includes(ext)) {
    return 'image'
  } else {
    return ext
  }
}

export function getClass(variables, annotation) {
  if (!variables.annotatorData && !variables.assignment) {
    return {
      code: '',
      color: '',
      name: ''
    }
  }
  const classification = variables.annotatorData.classification
  if (classification && classification[annotation.tool]) {
    const properties = classification[annotation.tool].classification_schema.properties
    let enums = []
    let enumNames = []
    let enumValues = []
    for (const key of Object.keys(properties)) {
      if (properties[key].code === 'class') {
        enums = properties[key].enum
        enumNames = properties[key].enumNames
        enumValues = properties[key].enumValues
      }
    }
    const idx = enums.indexOf(annotation.classification.class)
    if (idx === -1) {
      return {
        code: '',
        color: '',
        name: ''
      }
    }
    return {
      code: enums[idx],
      color: enumValues[idx],
      name: enumNames[idx]
    }
  } else {
    return {
      code: '',
      color: '',
      name: ''
    }
  }
}

export function initClassSelection(variables) {
  const jsonSchema = variables.annotatorData.classification
  const annotationTypes = Object.keys(jsonSchema)

  const state = {}
  for (const annotationType of annotationTypes) {
    const schemaProps = jsonSchema[annotationType].classification_schema.properties
    state[annotationType] = { classification: { class: '' } }
    for (const propId of Object.keys(schemaProps)) {
      const prop = schemaProps[propId]

      if (prop.default.length < 1) continue

      if (prop.condition) {
        const splitRef = prop.condition.$ref.split('/')
        const classPropId = splitRef[splitRef.length - 1]

        if (schemaProps[classPropId].default !== prop.condition.const) continue
      }

      state[annotationType].classification = { ...state[annotationType].classification, [prop.code]: prop.default }
    }
  }
  return state
}

export function osKey(windowChar, macChar) {
  if (navigator.appVersion.indexOf('Mac') !== -1) {
    return macChar
  } else {
    return windowChar
  }
}

export function getMetaKeyPressed(event) {
  return navigator.userAgentData.platform.includes('mac') ? event.metaKey : event.ctrlKey
}

export function conversionHsl(hex) {
  if (!hex) {
    return hex
  }

  if (hex.toLowerCase().indexOf('hsl') === 0) {
    const arr = hex.split(',')
    for (let i = 0; i < arr.length; i++) {
      if (i === 0) {
        arr[i] = arr[i].split('(')[1]
      }
      arr[i] = parseInt(arr[i])
    }
    hex = hsl(arr[0], arr[1], arr[2])
  }
  return hex
}

export function hexStrToHexNum(hex) {
  return parseInt(hex.replace(/^#/, ''), 16)
}

export function hexToNum(hex) {
  return chroma(hex).num()
}

export function getTextColor(backgroundColor) {
  if (backgroundColor) {
    if (chroma(backgroundColor).luminance() <= 0.5) {
      return '#ffffff'
    } else {
      return '#000000'
    }
  }
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function useDynamicScript(args) {
  return new Promise((resolve, reject) => {
    if (args.url) {
      const element = document.createElement('script')
      document.head.appendChild(element)
      element.addEventListener('load', () => {
        resolve()
      })

      element.addEventListener('error', () => {
        reject(new Error(`Dynamic Script Error: ${args.url}`))
      })

      element.setAttribute('src', args.url)
      element.setAttribute('type', 'text/javascript')
      element.setAttribute('async', 'true')

      document.head.removeChild(element)
    }
  })
}

export async function loadComponent(url, scope, module) {
  await useDynamicScript({ url })
  return async () => {
    // eslint-disable-next-line no-undef
    await __webpack_init_sharing__('default')
    const container = window[scope]
    // eslint-disable-next-line no-undef
    await container.init(__webpack_share_scopes__.default)
    const factory = await window[scope].get(module)
    const Module = factory()
    return Module
  }
}

export async function useRemoteScript(url, scope, module) {
  const m = await loadComponent(url, scope, module)
  try {
    return await m()
  } catch (e) {
    console.error(e)
  }
}

export function fullscreen(element) {
  const rfs =
    element.requestFullscreen ||
    element.webkitRequestFullScreen ||
    element.mozRequestFullScreen ||
    element.msRequestFullscreen
  rfs.call(element)
}

export function exitFullscreen() {
  const efs =
    document.exitFullscreen ||
    document.webkitExitFullscreen ||
    document.mozCancelFullScreen ||
    document.msExitFullscreen
  efs.call(document)
}

export function isFullscreenMode() {
  return (
    document.fullscreenElement ||
    document.webkitFullscreenElement ||
    document.mozFullScreenElement ||
    document.msFullscreenElement
  )
}

export const createIntegerIdGenerator = function (start) {
  let id = start
  return function () {
    id += 1
    return id
  }
}

export const getLastInstanceId = (annotations) => {
  if (annotations.length === 0) {
    return 0
  }
  const instanceStringIdSet = new Set()
  const instanceIntegerIdSet = new Set()
  for (const annotation of annotations) {
    if (annotation.instanceId) {
      if (Number.isSafeInteger(annotation.instanceId)) {
        instanceIntegerIdSet.add(annotation.instanceId)
      } else {
        instanceStringIdSet.add(annotation.instanceId)
      }
    }
  }

  const integerArray = Array.from(instanceIntegerIdSet)
  let lastIntegerValue = 0
  if (integerArray.length > 0) {
    lastIntegerValue = integerArray.sort((a, b) => a - b).pop()
  }

  for (const instanceId of Array.from(instanceStringIdSet)) {
    lastIntegerValue++
    annotations.forEach((annotation) => {
      if (annotation.instanceId && annotation.instanceId === instanceId) {
        annotation.instanceId = lastIntegerValue
      }
    })
  }

  return lastIntegerValue
}

export function handleErrorLog(variables, messages, type) {
  const id = nanoid(10)
  if (!Array.isArray(messages)) {
    messages = [messages]
  }

  for (const message of messages) {
    variables.errorLog.errorList.push({
      message,
      timestamp: new Date(),
      selectedErrorId: id
    })
    switch (type) {
      case 'error':
        Vue.$toast.error(message.replaceAll('\n', '<br/>'))
        break
      case 'warning':
        Vue.$toast.warning(message.replaceAll('\n', '<br/>'))
        break
      default:
        Vue.$toast.error(message.replaceAll('\n', '<br/>'))
        break
    }
  }
}

export function initAnnotationOptions(annotation) {
  if (annotation.isDrawCompleted === undefined) {
    annotation.isDrawCompleted = true
  }
  if (annotation.isVisible === undefined) {
    set(annotation, 'isVisible', true)
  }
  if (annotation.isLocked === undefined) {
    set(annotation, 'isLocked', false)
  }
}

export function initRelationOptions(relation) {
  if (relation.isVisible === undefined) {
    set(relation, 'isVisible', true)
  }
  if (relation.isLocked === undefined) {
    set(relation, 'isLocked', false)
  }
}

export function isEmpty(obj) {
  return Object.keys(obj).length === 0
}

export function initAnnotationGroupOptions(annotationGroup) {
  if (annotationGroup.isLocked === undefined) {
    set(annotationGroup, 'isLocked', false)
  }
  if (annotationGroup.annotationList === undefined) {
    set(annotationGroup, 'annotationList', [])
  }
  if (annotationGroup.isItemVisible === undefined) {
    set(annotationGroup, 'isItemVisible', false)
  }
}

function getRandomValue(range = 256) {
  return Math.floor(Math.random() * range)
}

export function getRandomRGB() {
  const r = getRandomValue()
  const g = getRandomValue()
  const b = getRandomValue()

  return `rgb(${r}, ${g}, ${b})`
}

export function getRandomColorHex() {
  return chroma(getRandomRGB()).hex()
}
