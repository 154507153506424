const state = () => ({
  imageLoaded: false,
  world: { x: 0, y: 0 },
  preferences: {
    guide: {
      color: '#000000',
      boundary_box: 'enable',
      boundary_boxes: { w: 0, h: 0 },
      line_width: 1,
      grid: 'enable',
      grid_horizontal_count: 0,
      grid_vertical_count: 0,
      grid_color: '#000000',
      grid_line_width: 1,
      dynamic_color: '#000000',
      cursor_coordinates: 'enable',
      showGuideline: true
    },
    general: {
      mini_map: 'disabled',
      mini_map_image_visible: 'enabled',
      mini_map_show_annotations: 'all',
      mini_map_background_color: '#fff',
      edge_detect: 'enable',
      annotation_stroke_width: 1,
      annotation_stroke_color: '#2ddf00',
      selected_annotation_stroke_width: 3,
      selected_annotation_stroke_color: '#37589a',
      anchor_point_size: 5,
      contrast: 0,
      brightness: 1,
      grayScale: 'disable',
      invert: 'disable',
      ruler: 'enable',
      scaleFactor: 10,
      showBoundingBoxCrossStroke: false,
      showBoundingBoxCenterPoint: false,
      showArea: false,
      selectWithRelations: false,
      useBoundingBoxRotation: false
    },
    labs: {}
  }
})

// getters
const getters = {
  imageLoaded(state) {
    return state.imageLoaded
  },
  preferences(state) {
    return state.preferences
  },
  guide(state, getters) {
    return getters.preferences.guide
  },
  general(state, getters) {
    return getters.preferences.general
  },
  labs(state) {
    return state.preferences.labs
  },
  world(state) {
    return state.world
  }
}

// actions
const actions = {
  changeGuideToolState({ commit }, toolName, value) {
    commit('changeGuideToolState', toolName, value)
  },
  changeGeneralToolState({ commit }, toolName, value) {
    commit('changeGeneralToolState', toolName, value)
  },
  setImageLoadingState({ commit }, status) {
    commit('setImageLoadingState', status)
  },
  setPreferencesGuide({ commit }, guide) {
    commit('setPreferencesGuide', guide)
  },
  setPreferencesGeneral({ commit }, general) {
    commit('setPreferencesGeneral', general)
  },
  setPreferencesLabs({ commit }, labs) {
    commit('setPreferencesLabs', labs)
  }
}

// mutations
const mutations = {
  changeGuideToolState(state, { toolName, value }) {
    state.preferences.guide[toolName] = value
  },
  changeGeneralToolState(state, { toolName, value }) {
    state.preferences.general[toolName] = value
  },
  setImageLoadingState(state, status) {
    state.imageLoaded = status
  },
  setPreferencesGuide(state, guide) {
    state.preferences.guide = { ...guide }
  },
  setPreferencesGeneral(state, general) {
    state.preferences.general = { ...general }
  },
  setPreferencesLabs(state, labs) {
    state.preferences.labs = { ...labs }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
