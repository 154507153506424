<template>
  <div v-if="userDelegator" class="bg-danger fixed-top" style="z-index: 1040; height: 40px">
    <span class="text-white d-flex justify-content-between px-4" style="margin-top: 0.6rem">
      <div class="text-truncate">
        {{
          $t('dashboard.term.userLoginAsMsg', {
            user: `${userDelegator.name}(${userDelegator.email})`,
            userLoginAs: `${user.name}(${user.email})`
          })
        }}
      </div>
      <strong style="min-width: 145px">
        <a class="text-white cursor-pointer" @click="userLogoutAs">
          {{ $t('dashboard.term.userLogoutAs') }}
        </a>
      </strong>
    </span>
  </div>
</template>

<script>
import { WorkspaceToken } from '@/app/shared/auth/workspace-token'

export default {
  name: 'login-banner.vue',
  setup() {
    const user = JSON.parse(localStorage.getItem('user'))
    const userDelegator = JSON.parse(localStorage.getItem('userDelegator'))

    const userLogoutAs = () => {
      if (JSON.parse(localStorage.getItem('userDelegator'))) {
        localStorage.setItem('user', localStorage.getItem('userDelegator'))
        localStorage.setItem('userDelegator', null)
        WorkspaceToken.set('', false)
      }
      location.href = '/'
    }

    return {
      user,
      userDelegator,
      userLogoutAs
    }
  }
}
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
