import 'bootstrap'
import VueFormulate from '@braid/vue-formulate'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import FormulateVSelectPlugin from '@cone2875/vue-formulate-select'
import * as Sentry from '@sentry/vue'
import VueCompositionAPI from '@vue/composition-api'
import { AlertPlugin, BootstrapVue, BootstrapVueIcons, IconsPlugin, ToastPlugin } from 'bootstrap-vue'
import VJsoneditor from 'v-jsoneditor'
import Vue from 'vue'
import vSelect from 'vue-select'
import VueToast from 'vue-toast-notification'

import i18n from './i18n'
import App from '@/app/app'
import router from '@/app/app-routes'
import store from '@/app/app-store'
import { useLog } from '@/app/core/lib/util'
import AjaxMultiSelect from '@/app/dashboard/components/shared/form/ajax-multi-select'
import AjaxSelect from '@/app/dashboard/components/shared/form/ajax-select'
import AllocationInput from '@/app/dashboard/components/shared/form/allocation-input'
import CkEditorInput from '@/app/dashboard/components/shared/form/ck-editor-input'
import ClassificationInput from '@/app/dashboard/components/shared/form/classification-input'
import CustomBase64FileInput from '@/app/dashboard/components/shared/form/custom-base64-file-input'
import CustomDateInput from '@/app/dashboard/components/shared/form/custom-date-input'
import CustomFileInput from '@/app/dashboard/components/shared/form/custom-file-input'
import CustomHelpInput from '@/app/dashboard/components/shared/form/custom-help-input'
import CustomLabelInput from '@/app/dashboard/components/shared/form/custom-label-input'
import CustomNormalInput from '@/app/dashboard/components/shared/form/custom-normal-input.vue'
import CustomOptionSelect from '@/app/dashboard/components/shared/form/custom-option-select'
import EmailVerificationInput from '@/app/dashboard/components/shared/form/email-verification-input'
import IamportInput from '@/app/dashboard/components/shared/form/iamport-input'
import JsonEditorInput from '@/app/dashboard/components/shared/form/json-editor-input'
import JsonSchemaInput from '@/app/dashboard/components/shared/form/json-schema-input'
import SkipInput from '@/app/dashboard/components/shared/form/skip-input'
import TagInput from '@/app/dashboard/components/shared/form/tag-input'
import CustomDatasetInput from '@/app/dashboard/components/shared/form/train/custom-dataset-input'
import PageHeader from '@/app/dashboard/components/shared/page-header'

import 'vue-toast-notification/dist/theme-sugar.css'

// eslint-disable-next-line import/order
import VueWorker from 'vue-worker'
import { initClipboard } from '@/app/shared/utils/Clipboard'

Vue.config.productionTip = false

Vue.prototype.$cvready = {}
Vue.prototype.$cvready.cbs = []
Vue.prototype.$cvready.addCb = function (fn) {
  Vue.prototype.$cvready.cbs.push(fn)
}
Vue.component('CustomOptionSelect', CustomOptionSelect)
Vue.component('AjaxSelect', AjaxSelect)
Vue.component('AjaxMultiSelect', AjaxMultiSelect)
Vue.component('ClassificationInput', ClassificationInput)
Vue.component('JsonSchemaInput', JsonSchemaInput)
Vue.component('TagInput', TagInput)
Vue.component('CkEditorInput', CkEditorInput)
Vue.component('JsonEditorInput', JsonEditorInput)
Vue.component('EmailVerificationInput', EmailVerificationInput)
Vue.component('CustomDatasetInput', CustomDatasetInput)
Vue.component('CustomLabelInput', CustomLabelInput)
Vue.component('CustomNormalInput', CustomNormalInput)
Vue.component('CustomHelpInput', CustomHelpInput)
Vue.component('IamportInput', IamportInput)
Vue.component('CustomFileInput', CustomFileInput)
Vue.component('SkipInput', SkipInput)
Vue.component('CustomBase64FileInput', CustomBase64FileInput)
Vue.component('AllocationInput', AllocationInput)
Vue.component('CustomDateInput', CustomDateInput)

Vue.use(VueWorker)
Vue.use(CKEditor)
Vue.use(VJsoneditor)
Vue.use(VueFormulate, {
  plugins: [FormulateVSelectPlugin],
  classes: {
    outer: 'form-group',
    input: 'form-control',
    inputHasErrors: 'is-invalid',
    help: 'form-text text-muted',
    errors: 'list-unstyled text-danger'
  },
  library: {
    custom_option_select: {
      classification: 'text',
      component: 'CustomOptionSelect',
      slotProps: {
        label: ['optionType']
      }
    },
    tag_input: {
      classification: 'text',
      component: 'TagInput',
      slotProps: {
        errors: ['errorText']
      }
    },
    ck_editor_input: {
      classification: 'text',
      component: 'CkEditorInput'
    },
    json_editor_input: {
      classification: 'text',
      component: 'JsonEditorInput'
    },
    ajax_select: {
      classification: 'text',
      component: 'AjaxSelect',
      slotProps: {
        label: [
          'extraOptions',
          'endpoint',
          'optionValue',
          'optionLabel',
          'computedOption',
          'searchOnFocus',
          'searchable',
          'placeholder'
        ]
      }
    },
    ajax_multi_select: {
      classification: 'text',
      component: 'AjaxMultiSelect',
      slotProps: {
        label: [
          'endpoint',
          'optionValue',
          'optionLabel',
          'computedOption',
          'searchOnFocus',
          'isMultiple',
          'searchOptions'
        ]
      }
    },
    classification_input: {
      classification: 'text',
      component: 'ClassificationInput',
      slotProps: {
        label: ['category', 'annotationType', 'schemaType']
      }
    },
    json_schema_input: {
      classification: 'text',
      component: 'JsonSchemaInput',
      slotProps: {
        label: ['schema', 'annotationType', 'usedBy']
      }
    },
    email_verification_input: {
      classification: 'text',
      component: 'EmailVerificationInput'
    },
    custom_dataset_input: {
      classification: 'text',
      component: 'CustomDatasetInput'
    },
    custom_label_input: {
      classification: 'text',
      component: 'CustomLabelInput',
      slotProps: {
        label: ['labelText']
      }
    },
    custom_help_input: {
      classification: 'text',
      component: 'CustomHelpInput',
      slotProps: {
        help: ['helpText']
      }
    },
    custom_normal_input: {
      classification: 'text',
      component: 'CustomNormalInput',
      slotProps: {
        help: ['helpText']
      }
    },
    custom_date_input: {
      classification: 'text',
      component: 'CustomDateInput',
      slotProps: {
        help: ['helpText']
      }
    },
    custom_base64_file_input: {
      classification: 'text',
      component: 'CustomBase64FileInput'
    },
    iamport_input: {
      classification: 'text',
      component: 'IamportInput',
      slotProps: {
        label: ['form']
      }
    },
    custom_file_input: {
      classification: 'text',
      component: 'CustomFileInput',
      slotProps: {
        label: ['formFile', 'accept', 'deleteFile']
      }
    },
    skip_input: {
      classification: 'text',
      component: 'skipInput',
      slotProps: {
        label: ['skipType', 'canSkip']
      }
    },
    allocation_input: {
      classification: 'text',
      component: 'AllocationInput'
    }
  }
})
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(IconsPlugin)
Vue.use(VueCompositionAPI)
Vue.use(ToastPlugin)
Vue.use(AlertPlugin)
Vue.use(VueToast)

Vue.component('v-select', vSelect)
Vue.component('page-header', PageHeader)

const replay = new Sentry.Replay({
  maskAllText: false,
  blockAllMedia: false,
  maskAllInputs: false
})

Sentry.init({
  Vue,
  dsn: 'https://fd7cfc5c8128be23d4fe4dabf385f169@sentry.datamaker.io/3',
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.1,
  integrations: [replay],
  environment: process.env.VUE_APP_SENTRY_ENV
})

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')

const { log } = useLog()

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/cache-worker.js')
      .then(() => {
        log(`Cache worker installed.`)
      })
      .catch((registrationError) => {
        log('SW registration failed: ', registrationError)
      })
    initClipboard()
  })
}
