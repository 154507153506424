import axios from 'axios'
import { AuthToken } from '@/app/shared/auth/auth-token'
import { WorkspaceToken } from '@/app/shared/auth/workspace-token'
import { useErrorStore } from '@/app/shared/store/error'

const { store } = useErrorStore()
const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL
})

api.interceptors.request.use(
  async function (options) {
    const userDelegator = JSON.parse(localStorage.getItem('userDelegator'))
    const user = JSON.parse(localStorage.getItem('user'))
    if (userDelegator) {
      options.headers['DATAMAKER-Login-As'] = user.id
    }

    const authToken = AuthToken.get()
    const workspaceToken = WorkspaceToken.get()
    const language = localStorage.getItem('locale') || process.env.VUE_APP_I18N_LOCALE || 'en'
    options.headers['Accept-Language'] = language
    if (authToken) {
      options.headers.Authorization = `Token ${authToken}`
    }
    if (workspaceToken) {
      options.headers['DATAMAKER-Workspace'] = `Token ${workspaceToken}`
    }
    // 요청 파라미터 value 값이 비어있을 경우 params 삭제
    if (options.method === 'get' && options.params) {
      const params = new URLSearchParams()
      for (const [key, value] of Object.entries(options.params)) {
        if (value) {
          // 다중 필터를 위한 배열 params 대응
          if (Array.isArray(value) && value.length > 0) {
            for (let i = 0; i < value.length; i++) {
              params.append(key, value[i])
            }
          } else {
            params.append(key, value)
          }
        }
      }
      options.params = params
    }

    return options
  },
  function (error) {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  async function (options) {
    return options
  },
  function (error) {
    if (error.response) {
      switch (error.response.status) {
        case 400:
          if (error.response.data.non_field_errors) {
            error.response.errors = {
              show: 3,
              contents: error.response.data.non_field_errors.join()
            }
          } else if (Array.isArray(error.response.data.code)) {
            error.response.errors = {
              show: 3,
              contents: error.response.data.code.join()
            }
          } else if (
            error.response.data.file_specifications &&
            error.response.data.file_specifications.non_field_errors
          ) {
            error.response.errors = {
              show: 3,
              contents: error.response.data.file_specifications.non_field_errors.join()
            }
          } else if (error.response.data.verification && error.response.data.verification.non_field_errors) {
            error.response.errors = {
              show: 3,
              contents: error.response.data.verification.non_field_errors.join()
            }
          } else {
            if (error.response.data) {
              Object.keys(error.response.data)
              const errors = []
              for (const key in error.response.data) {
                errors.push({
                  field: key,
                  show: 3,
                  contents: error.response.data[key]
                })
              }
              error.response.errors = errors
            }
          }
          break

        case 401:
          AuthToken.set('', true)
          location.href = '/'
          break

        case 403:
          // if (error.response.data.code === 'not_verified') {
          //   location.href = '/auth/user_verification?redirect=' + encodeURIComponent(window.location.pathname + window.location.search)
          // }
          if (error.response.data.code === 'not_verified') {
            location.href =
              '/auth/user_waiting_approval?redirect=' +
              encodeURIComponent(window.location.pathname + window.location.search)
          }
          break

        case 500:
          store.dispatch('changeErrorState')
          break

        default:
          break
      }
      error.response.inputErrors = error.response.data
    }
    return Promise.reject(error)
  }
)

export default api
