import { set } from '@vue/composition-api'
import { cloneDeep } from 'lodash'
import { nanoid } from 'nanoid'
import { getLabel, initAnnotationOptions, initRelationOptions, initAnnotationGroupOptions } from '@/app/core/lib/util'

export const useLabels = (variables) => {
  if (variables?.annotatorData?.labels?.list?.length > 0) {
    return variables.annotatorData.labels.list
  }

  return []
}

export const useDataUnit = (variables) => {
  return { dataUnit: variables?.assignment?.label?.data_unit }
}

export const useAssignment = (variables) => {
  return { assignment: variables?.assignment }
}

export const usePrimaryLabelUrl = (variables) => {
  const labels = useLabels(variables)
  const primaryLabel = labels.find((d) => d.is_primary)
  if (primaryLabel) {
    return primaryLabel.url
  }
}

const callbackMap = {}

export const useAnnotation = (variables, id) => {
  let annotation
  let annotationData
  let index
  let key
  if (variables.annotatorData.labels.list && variables.annotatorData.labels.list.length > 0) {
    const labels = useLabels(variables)
    key = labels.find((d) => d.is_primary).id
    index = variables.annotatorData.annotations[key].findIndex((d) => d.id === id)
    annotation = variables.annotatorData.annotations[key][index]
    annotationData = variables.annotatorData.annotationsData[key][index]
    if (annotation && annotation.id !== annotationData.id) {
      annotationData = variables.annotatorData.annotationsData[key].find((d) => d.id === id)
    }
  }

  const onChange = (callback) => {
    if (callbackMap[id] === undefined) {
      callbackMap[id] = []
    }
    callbackMap[id].push(callback)
  }

  return {
    index: index + 1,
    key,
    annotation,
    annotationData,
    onChange
  }
}

export const useAnnotationGroup = (variables, id) => {
  let annotationGroup
  let index
  let key
  if (variables.annotatorData.labels.list && variables.annotatorData.labels.list.length > 0) {
    const labels = useLabels(variables)
    key = labels.find((d) => d.is_primary).id
    index = variables.annotatorData.annotationGroups[key].findIndex((d) => d.id === id)
    annotationGroup = variables.annotatorData.annotationGroups[key][index]
  }

  return {
    index: index + 1,
    key,
    annotationGroup
  }
}

export const useAnnotationGroups = (variables) => {
  let annotationGroups
  let key
  if (variables.annotatorData.labels.list?.length > 0) {
    const labels = useLabels(variables)
    key = labels.find((d) => d.is_primary).id
    annotationGroups = variables.annotatorData.annotationGroups[key]
  }

  return { annotationGroups }
}

export const useRelations = (variables) => {
  let relations
  let key
  if (variables?.annotatorData?.labels?.list?.length > 0) {
    const labels = useLabels(variables)
    key = labels.find((d) => d.is_primary).id
    relations = variables.annotatorData.relations[key]
  }

  return { relations }
}

export const useRelation = (variables, id) => {
  let relation
  if (variables?.annotatorData?.labels?.list?.length > 0) {
    const labels = useLabels(variables)
    const key = labels.find((d) => d.is_primary).id
    relation = variables.annotatorData.relations[key].find((d) => d.id === id)
  }

  return relation
}

export const isSequentialDataUnit = (variables) => {
  const assignment = variables.assignment
  const maxIndex = assignment?.label?.data_unit?.meta?.['max_index']
  const sequenceIndex = variables.sequenceIndex

  return maxIndex > 0 && sequenceIndex > -1
}

export const useAnnotations = (variables) => {
  const labels = useLabels(variables)
  const key = labels.find((d) => d.is_primary).id
  const keys = Object.keys(variables.annotatorData.annotations)
  if (!keys.includes(key)) {
    const msg = `No primary key on annotatorData. Primary key: '${key}'. Exist keys: ${JSON.stringify(keys)}`
    throw new Error(msg)
  }
  const annotations = variables.annotatorData.annotations[key]
  const annotationsData = variables.annotatorData.annotationsData[key]
  const { relations } = useRelations(variables)

  if (isSequentialDataUnit(variables)) {
    return {
      annotations: annotations.filter((d) => d.sequenceIndex === variables.sequenceIndex),
      annotationsData: annotationsData.filter((d) => d.sequenceIndex === variables.sequenceIndex),
      relations: relations.filter((d) => d.sequenceIndex === variables.sequenceIndex) || []
    }
  } else {
    return {
      annotations,
      annotationsData,
      relations: relations || []
    }
  }
}

export const getAnnotationIdList = (variables) => {
  const labels = useLabels(variables)
  const key = labels.find((d) => d.is_primary).id
  const annotations = variables.annotatorData.annotations[key]

  if (isSequentialDataUnit(variables)) {
    return annotations.filter((d) => d.sequenceIndex === variables.sequenceIndex).map((d) => d.id)
  } else {
    return annotations.map((d) => d.id)
  }
}

export const useAnnotationsAllSequence = (variables) => {
  const labels = useLabels(variables)
  const key = labels.find((d) => d.is_primary).id
  const annotations = variables.annotatorData.annotations[key]
  const annotationsData = variables.annotatorData.annotationsData[key]
  const { relations } = useRelations(variables)

  return {
    annotations,
    annotationsData,
    relations: relations || []
  }
}

export const pushAnnotationGroup = (variables, annotationGroup, index) => {
  const labels = useLabels(variables)
  const key = labels.find((d) => d.is_primary).id
  if (!key) return

  const { annotationGroups } = useAnnotationGroups(variables)

  initAnnotationGroupOptions(annotationGroup)
  if (annotationGroup.isValid === undefined) {
    set(annotationGroup, 'isValid', false)
  }

  if (index !== undefined) {
    annotationGroups.splice(index, 0, annotationGroup)
  } else {
    annotationGroups.push(annotationGroup)
  }
}

export const pushAnnotation = (variables, annotation, annotationData, index = undefined) => {
  if (annotation.id !== annotationData.id) return false

  initAnnotationOptions(annotation)
  if (annotation.isValid === undefined) {
    annotation.isValid = false
  }

  const assignment = variables.assignment
  const maxIndex = assignment?.label?.data_unit?.meta?.['max_index']

  if (maxIndex > 0) {
    if (annotation.instanceId === undefined) {
      if (!variables.generateInstanceId) {
        throw new Error('generateInstanceId is not defined')
      }
      set(annotation, 'instanceId', variables.generateInstanceId())
    }
  }

  const labels = useLabels(variables)
  const key = labels.find((d) => d.is_primary).id

  if (key) {
    if (!variables.annotatorData.annotations[key]) {
      set(variables.annotatorData.annotations, key, [])
    }

    if (!variables.annotatorData.annotationsData[key]) {
      set(variables.annotatorData.annotationsData, key, [])
    }

    if (!variables.annotatorData.annotationGroups[key]) {
      set(variables.annotatorData.annotationGroups, key, [])
    }

    const { annotations, annotationsData } = useAnnotations(variables)

    if (annotation.classification) {
      annotation.label = getLabel(variables, annotation.id, annotation.tool, annotation.classification)
    }
    if (index !== undefined) {
      annotations.splice(index, 0, annotation)
      annotationsData.splice(index, 0, annotationData)
    } else {
      if (annotations.filter((d) => d.id === annotation.id).length > 0) {
        variables.annotatorData.annotations[key] = variables.annotatorData.annotations[key].filter(
          (d) => d.id !== annotation.id
        )
      }
      if (annotationsData.filter((d) => d.id === annotationData.id).length > 0) {
        variables.annotatorData.annotationsData[key] = variables.annotatorData.annotationsData[key].filter(
          (d) => d.id !== annotation.id
        )
      }

      if (isSequentialDataUnit(variables)) {
        annotation.sequenceIndex = variables.sequenceIndex
        annotationData.sequenceIndex = variables.sequenceIndex
      }

      // Check if the annotation id is already in the list
      if (
        variables.annotatorData.annotations[key].filter((d) => d.id === annotation.id).length === 0 &&
        variables.annotatorData.annotationsData[key].filter((d) => d.id === annotation.id).length === 0
      ) {
        variables.annotatorData.annotations[key].push(annotation)
        variables.annotatorData.annotationsData[key].push(annotationData)
      } else {
        throw new Error(`The annotation id ${annotation.id} is already in the list`)
      }
    }
  }
}

export const garbageCollection = (variables) => {
  const labels = useLabels(variables)
  const key = labels.find((d) => d.is_primary).id

  if (key && variables.annotatorData.annotations[key] && variables.annotatorData.annotationsData[key]) {
    const annotationsIds = variables.annotatorData.annotations[key].map((d) => d.id)
    const annotationsDataIds = variables.annotatorData.annotationsData[key].map((d) => d.id)

    const difference = annotationsIds
      .filter((x) => !annotationsDataIds.includes(x))
      .concat(annotationsDataIds.filter((x) => !annotationsIds.includes(x)))

    variables.annotatorData.annotations[key] = variables.annotatorData.annotations[key].filter(
      (d) => !difference.includes(d.id)
    )
    variables.annotatorData.annotationsData[key] = variables.annotatorData.annotationsData[key].filter(
      (d) => !difference.includes(d.id)
    )
    variables.annotatorData.relations[key] = variables.annotatorData.relations[key].filter(
      (d) => !difference.includes(d.annotationId) && !difference.includes(d.targetAnnotationId)
    )
  }

  if (key && variables.annotatorData.relations[key]) {
    variables.annotatorData.relations[key] = variables.annotatorData.relations[key].filter(
      (d) => d.annotationId && d.targetAnnotationId
    )
  }

  if (variables.annotatorData.annotations['']) {
    delete variables.annotatorData.annotations['']
  }
  if (variables.annotatorData.annotationsData['']) {
    delete variables.annotatorData.annotationsData['']
  }
  if (variables.annotatorData.relations['']) {
    delete variables.annotatorData.relations['']
  }

  if (isSequentialDataUnit(variables)) {
    variables.annotatorData.annotations[key] = variables.annotatorData.annotations[key].filter(
      (d) => d.sequenceIndex !== undefined || d.sequenceIndex < 0
    )
    variables.annotatorData.annotationsData[key] = variables.annotatorData.annotationsData[key].filter(
      (d) => d.sequenceIndex !== undefined || d.sequenceIndex < 0
    )
    variables.annotatorData.relations[key] = variables.annotatorData.relations[key].filter(
      (d) => d.sequenceIndex !== undefined || d.sequenceIndex < 0
    )
  }
}

export const removeIsNotDrawn = (variables) => {
  const labels = useLabels(variables)
  const key = labels.find((d) => d.is_primary).id

  if (key && variables.annotatorData.annotations[key] && variables.annotatorData.annotationsData[key]) {
    const annotationsIds = variables.annotatorData.annotations[key].filter((d) => d.isDrawCompleted).map((d) => d.id)
    const annotationsDataIds = variables.annotatorData.annotationsData[key].map((d) => d.id)

    const difference = annotationsIds
      .filter((x) => !annotationsDataIds.includes(x))
      .concat(annotationsDataIds.filter((x) => !annotationsIds.includes(x)))

    variables.annotatorData.annotations[key] = variables.annotatorData.annotations[key].filter(
      (d) => !difference.includes(d.id)
    )
    variables.annotatorData.annotationsData[key] = variables.annotatorData.annotationsData[key].filter(
      (d) => !difference.includes(d.id)
    )
    variables.annotatorData.relations[key] = variables.annotatorData.relations[key].filter(
      (d) => !difference.includes(d.annotationId) && !difference.includes(d.targetAnnotationId)
    )
  }
}
export const updateAnnotation = (variables, id, changeObj) => {
  const labels = useLabels(variables)
  const key = labels.find((d) => d.is_primary).id

  if (changeObj.annotation) {
    variables.annotatorData.annotations[key] = variables.annotatorData.annotations[key].map((annotation) => {
      if (annotation.id === id) {
        const result = { ...annotation, ...changeObj.annotation }
        if (result.classification) {
          result.label = getLabel(variables, result.id, result.tool, result.classification)
        }
        return result
      } else {
        return annotation
      }
    })
  }
  if (changeObj.annotationData) {
    variables.annotatorData.annotationsData[key] = variables.annotatorData.annotationsData[key].map(
      (annotationData) => {
        if (annotationData.id === id) {
          return { ...annotationData, ...changeObj.annotationData }
        } else {
          return annotationData
        }
      }
    )
  }

  if (callbackMap[id]) {
    callbackMap[id].forEach((callback) => {
      callback({
        annotation: { ...changeObj.annotation },
        annotationData: { ...changeObj.annotationData }
      })
    })
  }
}

export const deleteAnnotationGroup = (variables, id) => {
  const labels = useLabels(variables)
  const key = labels.find((d) => d.is_primary).id
  variables.annotatorData.annotationGroups[key] = variables.annotatorData.annotationGroups[key].filter(
    (d) => d.id !== id
  )
}

export const deleteAnnotation = (variables, annotationIdList) => {
  const ids = []
  const lockedAnnotationIdList = []
  const hiddenAnnotationIdList = []

  for (let i = 0; i < annotationIdList.length; i++) {
    const id = annotationIdList[i]
    const { annotation } = useAnnotation(variables, id)

    if (annotation.isVisible && !annotation.isLocked) ids.push(id)
    if (annotation.isLocked) lockedAnnotationIdList.push(id)
    if (!annotation.isVisible) hiddenAnnotationIdList.push(id)
  }

  const labels = useLabels(variables)
  const key = labels.find((d) => d.is_primary).id

  if (variables.annotatorData.annotationGroups && variables.annotatorData.annotationGroups[key]) {
    const groups = variables.annotatorData.annotationGroups[key]
    for (let i = 0; i < groups.length; i++) {
      const group = groups[i]
      group.annotationList = group.annotationList.filter((d) => !ids.includes(d.annotationId))
    }
  }

  const cloneAnnotation = {
    ids,
    annotations: cloneDeep(variables.annotatorData.annotations[key].filter((d) => ids.includes(d.id))),
    annotationsData: cloneDeep(variables.annotatorData.annotationsData[key].filter((d) => ids.includes(d.id)))
  }

  if (!Array.isArray(variables.annotatorData.relations[key])) {
    variables.annotatorData.relations[key] = []
  }

  const relationIds = variables.annotatorData.relations[key]
    .filter((d) => ids.includes(d.annotationId) || ids.includes(d.targetAnnotationId))
    .map((d) => {
      return {
        annotationId: d.annotationId,
        targetAnnotationId: d.targetAnnotationId
      }
    })

  variables.annotatorData.annotations[key] = variables.annotatorData.annotations[key].filter((d) => !ids.includes(d.id))
  variables.annotatorData.annotationsData[key] = variables.annotatorData.annotationsData[key].filter(
    (d) => !ids.includes(d.id)
  )

  variables.annotatorData.relations[key] = variables.annotatorData.relations[key].filter(
    (d) => !ids.includes(d.annotationId) && !ids.includes(d.targetAnnotationId)
  )
  if (variables?.selectedAnnotationIdList?.value) {
    variables.selectedAnnotationIdList.value = variables.selectedAnnotationIdList.value.filter((d) => !ids.includes(d))
  }

  if (variables.callback.deleteAnnotationCallback) {
    variables.callback.deleteAnnotationCallback(cloneAnnotation)
  }

  if (variables.callback.deleteRelationCallback && relationIds.length > 0) {
    variables.callback.deleteRelationCallback(relationIds)
  }

  return { lockedAnnotationIdList, hiddenAnnotationIdList }
}

export const findAnnotationIndex = (variables, id) => {
  const { annotations } = useAnnotationsAllSequence(variables)

  return annotations.findIndex((d) => d.id === id)
}

export const pushRelation = (variables, relation, index) => {
  const labels = useLabels(variables)
  const key = labels.find((d) => d.is_primary).id
  if (!variables.annotatorData.relations[key]) {
    set(variables.annotatorData.relations, key, [])
  }

  if (relation.targetAnnotationId) {
    const idx = variables.annotatorData.relations[key].findIndex(
      (d) => d.annotationId === relation.annotationId && d.targetAnnotationId === relation.targetAnnotationId
    )
    initRelationOptions(relation)

    if (idx === -1) {
      if (relation.targetAnnotationId !== relation.annotationId) {
        relation.id = relation.annotationId + relation.targetAnnotationId
        if (relation.classification) {
          relation.label = getLabel(
            variables,
            relation.annotationId + relation.targetAnnotationId,
            'relation',
            relation.classification
          )
        }
        if (relation.isValid === undefined) {
          relation.isValid = false
        }
        if (index !== undefined) {
          variables.annotatorData.relations[key].splice(index, 0, relation)
        } else {
          variables.annotatorData.relations[key].push(relation)
        }
      }
    }
  }
}

export const moveAnnotationOrder = (variables, sourceIndex, destinationIndex) => {
  const { annotations, annotationsData } = useAnnotationsAllSequence(variables)
  const getItemInfo = (array) => array.splice(sourceIndex, 1)[0]

  const annotationInfo = getItemInfo(annotations)
  const annotationDataInfo = getItemInfo(annotationsData)

  annotations.splice(destinationIndex, 0, annotationInfo)
  annotationsData.splice(destinationIndex, 0, annotationDataInfo)

  if (
    variables.annotationsFilter.annotationType !== 'all' &&
    variables.annotationsFilter.filteredAnnotationIdList.length > 0
  ) {
    const filteredArray = []
    for (let i = 0; i < annotations.length; i++) {
      const annotation = annotations[i]
      if (annotation.tool === variables.annotationsFilter.annotationType) {
        filteredArray.push(annotation.id)
      }
    }
    variables.annotationsFilter.filteredAnnotationIdList = filteredArray
  }
}

export const deleteRelation = (variables, annotationId, targetAnnotationId) => {
  const labels = useLabels(variables)
  const key = labels.find((d) => d.is_primary).id
  const relations = variables.annotatorData.relations[key]
  const lockedRelationList = relations.filter((d) => d.isLocked)
  variables.annotatorData.relations[key] = variables.annotatorData.relations[key].filter((d) => {
    if (d.isLocked || !d.isVisible) {
      return true
    } else {
      return !(d.annotationId === annotationId && d.targetAnnotationId === targetAnnotationId)
    }
  })
  variables.selectedAnnotationIdList.value = []
  variables.selectedRelationIdList.value = []

  if (variables.callback.deleteRelationCallback) {
    variables.callback.deleteRelationCallback([
      {
        annotationId,
        targetAnnotationId
      }
    ])
  }
  if (lockedRelationList.length > 0) {
    return lockedRelationList
  }
}

export const annotationCategory = {
  image_annotation: {
    ko: '이미지 어노테이션',
    en: 'Image annotation'
  },
  video_annotation: {
    ko: '비디오 어노테이션',
    en: 'Video annotation'
  },
  text_annotation: {
    ko: '텍스트 어노테이션',
    en: 'Text annotation'
  },
  pcd_annotation: {
    ko: '포인트클라우드 어노테이션',
    en: 'PCD annotation'
  },
  prompt_annotation: {
    ko: '프롬프트 어노테이션',
    en: 'Prompt annotation'
  }
}

export const annotationType = {
  keypoint: {
    ko: '키포인트',
    en: 'Keypoint'
  },
  polygon: {
    ko: '폴리곤',
    en: 'Polygon'
  },
  polyline: {
    ko: '폴리라인',
    en: 'Polyline'
  },
  bounding_box: {
    ko: '바운딩박스',
    en: 'Bounding box'
  }
}

export const annotatorMode = {
  'annotator-reviewer-mode': {
    ko: '검수자 모드',
    en: 'Reviewer Mode'
  },
  'annotator-labeler-mode': {
    ko: '라벨러 모드',
    en: 'Labeler Mode'
  },
  'annotator-ground-truth-mode': {
    ko: '정답 데이터 모드',
    en: 'Ground truth Mode'
  },
  'annotator-calculation-template-mode': {
    ko: '정답 템플릿 모드',
    en: 'Calculation template Mode'
  },
  'annotator-manager-mode': {
    ko: '관리자 모드',
    en: 'Manager Mode'
  }
}

export const getJsonSchema = (type = 'dm_schema', schema) => {
  if (type === 'dm_schema') {
    const data = cloneDeep(schema)
    const annotationTypes = Object.keys(data)
    if (annotationTypes && annotationTypes.length > 0) {
      for (let i = 0; i < annotationTypes.length; i++) {
        const annotationType = annotationTypes[i]
        const classificationId = nanoid(10)
        const jsonSchema = {
          type: 'object',
          required: [classificationId],
          properties: {
            [classificationId]: {
              widget: 'single_choice',
              type: 'string',
              name: '클래스',
              code: 'class',
              enum: [],
              enumNames: [],
              enumValues: [],
              default: '',
              customFields: []
            }
          },
          sort: [classificationId]
        }

        const classificationSchema = schema[annotationType].classification_schema
        for (let j = 0; j < classificationSchema.length; j++) {
          const c = classificationSchema[j]
          const classification = jsonSchema.properties[classificationId]
          classification.enum.push(c.code)
          classification.enumNames.push(c.name)
          classification.enumValues.push(c.value)
          if (c.customFields) {
            classification.customFields.push(c.customFields)
          } else {
            classification.customFields.push(null)
          }

          if (c.is_default) {
            classification.default = c.code
          }
          if (c.attributes && c.attributes.length > 0) {
            for (let k = 0; k < c.attributes.length; k++) {
              const a = c.attributes[k]
              const attributeId = nanoid(10)
              jsonSchema.properties[attributeId] = {
                type: 'string',
                code: a.code,
                name: a.name,
                widget: a.widget,
                enum: [],
                enumNames: [],
                enumValues: [],
                default: a.widget === 'multi_choice' ? [] : '',
                condition: {
                  $ref: `/properties/${classificationId}`,
                  const: c.code
                },
                customFields: []
              }
              if (a.is_required) {
                jsonSchema.required.push(attributeId)
              }
              jsonSchema.sort.push(attributeId)

              for (let l = 0; l < a.options.length; l++) {
                const o = a.options[l]
                const att = jsonSchema.properties[attributeId]
                att.enum.push(o.code)
                att.enumNames.push(o.name)
                att.enumValues.push(o.color)
                if (o.customFields) {
                  att.customFields.push(o.customFields)
                } else {
                  att.customFields.push(null)
                }

                if (o.is_default) {
                  if (a.widget === 'multi_choice') {
                    att.default.push(o.code)
                  } else {
                    att.default = o.code
                  }
                }
              }
            }
          }
        }

        data[annotationType].classification_schema = { ...jsonSchema }
      }
    }

    return data
  } else if (type === 'json_schema') {
    const schemaClone = cloneDeep(schema)
    const keys = Object.keys(schemaClone)
    for (let i = 0; i < keys.length; i++) {
      schemaClone[keys[i]].classification_schema = {
        ...schemaClone[keys[i]].classification_schema
      }
    }
    return schemaClone
  }
}

export const validateClassification = ({ list, worker, variables }) => {
  const schema = variables.annotatorData.classification
  return new Promise((resolve) => {
    worker
      .run(
        (list, schema) => {
          const dic = {}
          for (let i = 0; i < list.length; i++) {
            const d = list[i]
            if (schema[d.tool] === undefined) {
              dic[d.id] = true
              continue
            }
            const jsonSchema = schema[d.tool].classification_schema
            const required = jsonSchema.required
            const properties = jsonSchema.properties
            const sort = jsonSchema.sort
            const classification = d.classification

            let result = true

            for (let i = 0; i < sort.length; i++) {
              const key = sort[i]
              const property = properties[key]
              if (property.condition) {
                const referenceArray = property.condition.$ref.split('/')
                const referenceId = referenceArray[referenceArray.length - 1]
                const constant = property.condition.const
                const code = properties[referenceId].code
                if (classification[code] && classification[code] === constant) {
                  if (required.includes(key)) {
                    const val = classification[property.code]
                    if (!val || val.length === 0) {
                      result = false
                      break
                    }
                  }
                }
              } else {
                const val = classification[property.code]
                if (required.includes(key)) {
                  if (!val || val.length === 0) {
                    result = false
                    break
                  }

                  if (property.enum.length > 0) {
                    if (!property.enum.includes(val)) {
                      result = false
                      break
                    }
                  }
                }

                if (property.enum.length > 0) {
                  if (!property.enum.includes(val)) {
                    result = false
                    break
                  }
                }
              }
            }
            dic[d.id] = result
          }
          return dic
        },
        [list, schema]
      )
      .then((d) => resolve(d))
      .catch(console.error)
  })
}

export const useSequenceBuffer = (variables) => {
  return { sequenceBuffer: variables.sequenceBuffer || null }
}

export const setSessionId = () => {
  let sessionId = sessionStorage.getItem('sessionId')
  if (!sessionId) {
    sessionId = nanoid(10)
  }
  sessionStorage.setItem('sessionId', sessionId)
}

export const getSessionId = () => {
  return sessionStorage.getItem('sessionId')
}

export const cleanClassification = (_classification, essentialFields = []) => {
  const classification = { ..._classification }
  const keys = Object.keys(classification)
  keys.forEach((d) => {
    if (!essentialFields.includes(d) && d !== 'class') {
      delete classification[d]
    }
  })
  return classification
}

export const getEssentialFields = (variables, annotationTool, classification) => {
  const essentialFields = []
  const jsonSchema = getClassificationSchema(variables, annotationTool)
  const properties = jsonSchema.properties
  const propertyKeys = Object.keys(properties)
  const classPropertyId = propertyKeys.find((d) => properties[d].code === 'class')
  propertyKeys.splice(propertyKeys.indexOf(classPropertyId), 1)
  propertyKeys.forEach((d) => {
    const $ref = properties[d].condition.$ref
    const value = properties[d].condition.const
    const key = $ref.split('/')[2]
    const code = properties[key].code
    if (classification[code] === value) {
      essentialFields.push(properties[d].code)
    }
  })
  return essentialFields
}

export const getClassificationSchema = (variables, annotationTool) => {
  return variables.annotatorData.classification[annotationTool].classification_schema
}
