const pagesRoutes = [
  {
    path: 'terms',
    name: 'terms',
    component: () => import('@/app/dashboard/views/pages/terms'),
    meta: {
      requiresUserTag: false
    }
  },
  {
    path: 'privacy',
    name: 'privacy',
    component: () => import('@/app/dashboard/views/pages/privacy'),
    meta: {
      requiresUserTag: false
    }
  }
]

export default pagesRoutes
