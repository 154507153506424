<template>
  <div :class="`formulate-input-element formulate-input-element--${context.class}`" :data-type="context.type">
    <v-select v-model="model" :options="context.options">
      <template #option="option">
        <component :is="customOption" v-if="customOption" :option="option" />
      </template>
    </v-select>
  </div>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'

export default {
  name: 'custom-option-select',
  props: {
    context: {
      type: Object,
      required: true
    }
  },

  setup(props) {
    const model = ref(null)

    watch(
      computed(() => props.context.model),
      (v, oldValue) => {
        if (!oldValue) {
          model.value = props.context.options.find((d) => d.value === v)
        }
      }
    )

    watch(model, (v) => {
      if (v) {
        props.context.model = v.value
      } else {
        props.context.model = ''
      }
    })
    const customOption = computed(() => {
      const optionType = props.context.slotProps.label.optionType
      if (optionType) {
        return () => import('@/app/dashboard/components/shared/form/custom-options/' + optionType)
      }
      return null
    })
    return {
      model,
      customOption
    }
  }
}
</script>
